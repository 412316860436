import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import { Tooltip } from 'primereact/tooltip';

const SCORE_NAME_MAPPING = {
    'overall_quality_score': 'Quality',
    'overall_standards_score': 'Standards',
    'overall_documentation_score': 'Documentation',
    'overall_functionality_score': 'Functionality',
    'overall_security_score': 'Security',
    'overall_compatibility_score': 'Compatibility',
    'overall_architecture_score': 'Scalability',
};


const ScoreDistributionGraph = ({ files }) => {
    const svgRef = useRef(null);
    const containerRef = useRef(null);
    const [selectedTypes, setSelectedTypes] = useState(new Set());
    const [dimensions, setDimensions] = useState({ width: 0, height: 400 });

    // Update dimensions when container size changes
    useEffect(() => {
        if (!containerRef.current) return;

        const resizeObserver = new ResizeObserver(entries => {
            if (!entries[0]) return;
            const { width } = entries[0].contentRect;
            setDimensions({ width, height: 400 });
        });

        resizeObserver.observe(containerRef.current);
        return () => resizeObserver.disconnect();
    }, []);

    // Function to calculate score distributions
    const calculateDistributions = (files) => {
        if (!files?.length || !files[0]?.scores?.length) {
            return {};
        }

        const scoreTypes = files[0].scores.map(s => s.key).filter(Boolean);
        const distributions = {};

        // Initialize distributions with zeros
        scoreTypes.forEach(type => {
            if (type) {
                distributions[type] = Array(106).fill(0);
            }
        });

        // Count the occurrences of each score
        files.forEach(file => {
            if (!file?.scores) return;
            
            file.scores.forEach(score => {
                if (!score?.key || !distributions[score.key] || score.score === undefined || score.score === null) {
                    return;
                }

                const scoreValue = Math.round(Math.min(Math.max(score.score, 0), 100));
                
                // Add weighted values to neighboring points for smoother curves
                for (let i = -2; i <= 2; i++) {
                    const point = scoreValue + i;
                    if (point >= 0 && point <= 105 && distributions[score.key]) {
                        const weight = 1 - Math.abs(i) * 0.2;
                        distributions[score.key][point] += weight;
                    }
                }
            });
        });

        // Convert counts to percentages
        const totalFiles = files.length || 1;
        Object.keys(distributions).forEach(type => {
            if (distributions[type]) {
                distributions[type] = distributions[type].map(count => (count / totalFiles) * 100);
            }
        });

        return distributions;
    };

    // Initialize selected types when files change
    useEffect(() => {
        if (files?.length && files[0]?.scores) {
            // Only select Quality score initially
            const qualityScore = files[0].scores.find(s => s.key === 'overall_quality_score');
            if (qualityScore) {
                setSelectedTypes(new Set(['overall_quality_score']));
            } else {
                setSelectedTypes(new Set());
            }
        }
    }, [files]);

    useEffect(() => {
        if (!files?.length || !svgRef.current || !dimensions.width) return;

        d3.select(svgRef.current).selectAll("*").remove();

        const distributions = calculateDistributions(files);
        const scoreTypes = Object.keys(distributions);

        // Calculate legend layout
        const legendItemWidth = 150;
        const legendItemHeight = 25;
        const itemsPerRow = Math.max(1, Math.floor((dimensions.width - 120) / legendItemWidth));
        const legendRows = Math.ceil(scoreTypes.length / itemsPerRow);
        const legendHeight = legendRows * legendItemHeight;

        // Adjust margins based on screen width
        const margin = { 
            top: 20,
            right: 30, 
            bottom: dimensions.width < 768 ? 60 + legendHeight : 80 + legendHeight, // Reduced bottom margin for smaller screens
            left: 60
        };
        const width = dimensions.width - margin.left - margin.right;
        const height = dimensions.height - margin.top - margin.bottom;

        const svg = d3.select(svgRef.current)
            .attr("width", width + margin.left + margin.right)
            .attr("height", height + margin.top + margin.bottom)
            .append("g")
            .attr("transform", `translate(${margin.left},${margin.top})`);

        // Define clipping path
        svg.append("clipPath")
            .attr("id", "clip")
            .append("rect")
            .attr("width", width)
            .attr("height", height);

        // Create a group for all paths with clipping
        const pathsGroup = svg.append("g")
            .attr("clip-path", "url(#clip)");

        const x = d3.scaleLinear()
            .domain([0, 105])  // Reduced domain to 105 to limit drawing area
            .range([0, width]);

        const y = d3.scaleLinear()
            .domain([0, 100])
            .range([height, 0]);

        const line = d3.line()
            .x((d, i) => x(i))
            .y(d => y(d))
            .curve(d3.curveBasisOpen); // Changed to curveBasisOpen for smoother ends

        const area = d3.area()
            .x((d, i) => x(i))
            .y0(height)
            .y1(d => y(d))
            .curve(d3.curveBasisOpen); // Changed to curveBasisOpen for smoother ends

        // Define your custom colors here
        const color = d3.scaleOrdinal()
            .domain(scoreTypes)
            .range([
                '#32AFC3',  // Quality - Turquoise
                '#FF9800',  // Standards - Orange
                '#4CAF50',  // Documentation - Green
                '#E91E63',  // Functionality - Pink
                '#9C27B0',  // Security - Purple
                '#3F51B5',  // Compatibility - Indigo
                '#607D8B'   // Architecture - Blue Grey
            ]);

        // Add X axis with grey color and smaller font
        const xAxis = svg.append("g")
            .attr("transform", `translate(0,${height})`)
            .call(
                d3.axisBottom(x)
                .tickSize(6)
                .tickValues(d3.range(0, 101, 20)) // Only show ticks up to 100
            )
            .attr("color", "#9CA3AF")
            .style("font-size", "11px");

        // Remove any ticks beyond 100
        xAxis.selectAll(".tick")
            .filter(d => d > 100)
            .remove();

        // Add X axis label with responsive positioning
        xAxis.append("text")
            .attr("class", "x-axis-label")
            .attr("x", width / 2)
            .attr("y", dimensions.width < 768 ? 30 : margin.bottom / 3) // Adjust y position based on screen width
            .attr("fill", "#6c757d")
            .attr("text-anchor", "middle")
            .text("Score");

        // Add Y axis with grey color and smaller font
        const yAxis = svg.append("g")
            .call(
                d3.axisLeft(y)
                .tickSize(6)
                .ticks(5)
            )
            .attr("color", "#9CA3AF")
            .style("font-size", "11px");

        // Add horizontal grid lines BEFORE the data lines
        svg.append("g")
            .attr("class", "grid-lines")
            .selectAll("grid-line")
            .data(d3.range(0, 101, 10))
            .enter()
            .append("line")
            .attr("class", "grid-line")
            .attr("x1", 0)
            .attr("x2", width)
            .attr("y1", d => y(d))
            .attr("y2", d => y(d))
            .attr("stroke", "#E5E7EB")
            .attr("stroke-width", 1)
            .attr("stroke-dasharray", "2,2");

        // Add Y axis label with more spacing
        yAxis.append("text")
            .attr("transform", "rotate(-90)")
            .attr("y", -margin.left + 16)
            .attr("x", -height / 2)
            .attr("fill", "#6c757d")
            .attr("text-anchor", "middle")
            .text("Percentage of Files");

        // Add a vertical line for tooltip
        const tooltipLine = svg.append("line")
            .attr("class", "tooltip-line")
            .attr("y1", 0)
            .attr("y2", height)
            .style("opacity", 0)
            .style("stroke", "#9CA3AF")
            .style("stroke-width", 1)
            .style("stroke-dasharray", "3,3");

        // Add invisible overlay for mouse tracking
        const mouseArea = svg.append("rect")
            .attr("width", width)
            .attr("height", height)
            .attr("fill", "none")
            .attr("pointer-events", "all");

        // Mouse move handler
        mouseArea.on("mousemove", function(event) {
            const mouseX = d3.pointer(event)[0];
            const xValue = Math.round(x.invert(mouseX));
            
            if (xValue >= 0 && xValue <= 100) {
                tooltipLine
                    .attr("x1", x(xValue))
                    .attr("x2", x(xValue))
                    .style("opacity", 1);

                if (selectedTypes.size > 0) {
                    const tooltipContent = Array.from(selectedTypes)
                        .map(type => {
                            const value = distributions[type][xValue];
                            return `${value.toFixed(1)}% of files reached ${xValue} ${SCORE_NAME_MAPPING[type]} score`;
                        })
                        .join('\\n');

                    const tooltipTarget = d3.select(containerRef.current).select('#tooltip-target');
                    tooltipTarget
                        .attr('data-pr-tooltip', tooltipContent)
                        .style('position', 'absolute')
                        .style('left', `${event.offsetX}px`)
                        .style('top', `${event.offsetY}px`)
                        .style('opacity', 1)
                        .style('pointer-events', 'none');

                    // Highlight active curves
                    selectedTypes.forEach(type => {
                        svg.select(`.line-${type.replace(/\s+/g, '-')}`)
                            .attr("stroke-width", 3)
                            .classed("highlighted", true);
                    });
                }
            }
        });

        mouseArea.on("mouseleave", function() {
            tooltipLine.style("opacity", 0);
            d3.select(containerRef.current).select('#tooltip-target')
                .style('opacity', 0);
            
            // Reset curve styling
            selectedTypes.forEach(type => {
                svg.select(`.line-${type.replace(/\s+/g, '-')}`)
                    .attr("stroke-width", 2)
                    .classed("highlighted", false);
            });
        });

        // Add lines and areas for selected types
        scoreTypes.forEach(type => {
            const isSelected = selectedTypes.has(type);
            const typeColor = color(type);

            // Add area with transition (now in pathsGroup)
            pathsGroup.append("path")
                .datum(distributions[type].slice(0, 106)) // Extended to 105
                .attr("class", `area-${type.replace(/\s+/g, '-')}`)
                .attr("fill", typeColor)
                .attr("fill-opacity", isSelected ? 0.2 : 0)
                .attr("d", area)
                .style("transition", "fill-opacity 0.2s ease");

            // Add line with transition (now in pathsGroup)
            pathsGroup.append("path")
                .datum(distributions[type].slice(0, 106)) // Extended to 105
                .attr("class", `line-${type.replace(/\s+/g, '-')}`)
                .attr("fill", "none")
                .attr("stroke", typeColor)
                .attr("stroke-width", 2)
                .attr("stroke-opacity", isSelected ? 1 : 0.3)
                .attr("d", line)
                .style("transition", "stroke-width 0.2s ease, stroke-opacity 0.2s ease");
        });

        // Update legend positioning with responsive spacing
        const legendGroup = svg.append("g")
            .attr("class", "legend")
            .attr("transform", `translate(${width/2}, ${height + (dimensions.width < 768 ? 40 : margin.bottom / 3 + 20)})`); // Adjusted spacing for smaller screens

        const legendItems = legendGroup.selectAll("g")
            .data(scoreTypes)
            .enter()
            .append("g")
            .attr("class", "legend-item")
            .attr("transform", (d, i) => {
                const row = Math.floor(i / itemsPerRow);
                const col = i % itemsPerRow;
                const totalWidth = Math.min(scoreTypes.length, itemsPerRow) * legendItemWidth;
                const startX = -totalWidth / 2;
                return `translate(${startX + col * legendItemWidth}, ${row * legendItemHeight})`;
            })
            .style("cursor", "pointer")
            .on("click", (event, d) => {
                const newSelected = new Set(selectedTypes);
                if (newSelected.has(d)) {
                    newSelected.delete(d);
                } else {
                    newSelected.add(d);
                }
                setSelectedTypes(newSelected);

                // Update visuals
                const isNowSelected = newSelected.has(d);
                svg.select(`.line-${d.replace(/\s+/g, '-')}`)
                    .attr("stroke-opacity", isNowSelected ? 1 : 0.3);
                svg.select(`.area-${d.replace(/\s+/g, '-')}`)
                    .attr("fill-opacity", isNowSelected ? 0.2 : 0);
                
                // Update legend item appearance
                d3.select(event.currentTarget)
                    .select("text")
                    .attr("fill", isNowSelected ? "#323232" : "#6c757d");
                d3.select(event.currentTarget)
                    .select("rect")
                    .attr("fill-opacity", isNowSelected ? 0.2 : 0.1)
                    .attr("stroke-opacity", isNowSelected ? 1 : 0.3);
            });

        legendItems.append("rect")
            .attr("x", 0)
            .attr("width", 40)
            .attr("height", 20)
            .attr("rx", 4)
            .attr("fill", d => color(d))
            .attr("fill-opacity", d => selectedTypes.has(d) ? 0.2 : 0.1)
            .attr("stroke", d => color(d))
            .attr("stroke-width", 2)
            .attr("stroke-opacity", d => selectedTypes.has(d) ? 1 : 0.3);

        legendItems.append("text")
            .attr("x", 50)
            .attr("y", 15)
            .text(d => SCORE_NAME_MAPPING[d] || d)
            .attr("fill", d => selectedTypes.has(d) ? "#323232" : "#6c757d")
            .style("font-size", "15px");

    }, [files, selectedTypes, dimensions]);

    return (
        <div ref={containerRef} className="score-distribution-graph">
            <svg ref={svgRef}></svg>
            <div 
                id="tooltip-target" 
                style={{ 
                    position: 'absolute',
                    pointerEvents: 'none',
                    opacity: 0
                }} 
            />
            <Tooltip 
                target="#tooltip-target"
                position="right"
                className="score-distribution-prime-tooltip"
                showDelay={0}
                hideDelay={0}
            />
        </div>
    );
};

export default ScoreDistributionGraph; 