import React, { useState, useEffect } from 'react';
import { useParams, Navigate, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import axiosInstance from '../axiosConfig';
import { checkAuditStatus } from '../utils/Actions';
import DevelopmentActivityGraph from './development_component/DevelopmentActivityGraph';
import useAuditDetails from '../utils/useAuditDetails';
import PageLayout from '../components/PageLayout';
import FormattedText from '../components/FormattedText';
import HotspotsBubbleChart from './development_component/HotspotsBubbleChart';

import './Development.css';

const getTooltipDescription = (statType) => {
    const tooltipMap = {
        totalCommits: "Total number of code changes submitted to the repository, indicating development activity level",
        activeDays: "Number of unique days with development activity, showing consistent engagement",
        avgCommitsDay: "Average commits per active day, reflecting daily development intensity",
        totalAdditions: "Total lines of code added, representing new features and enhancements",
        totalDeletions: "Total lines of code removed, showing code optimization and refactoring",
        modifiedFiles: "Number of files changed, indicating scope of codebase modifications"
    };
    return tooltipMap[statType] || "";
};

const formatNumber = (number) => {
    return new Intl.NumberFormat('en-US').format(number);
};

export default function DevelopmentView() {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [shouldRedirect, setShouldRedirect] = useState(false);
    const { auditUuid } = useParams();
    const auditAccessDetails = useSelector(state => state.audits[auditUuid]);
    const navigate = useNavigate();

    // Use the new hook to get audit details
    const { 
        auditDetails, 
        headerProps, 
        loading: auditLoading, 
        isDataLoaded 
    } = useAuditDetails(auditUuid);

    // State for git statistics
    const [gitStats, setGitStats] = useState({
        timeBasedStats: null,
        authorStats: null,
        codeChurnStats: null,
        collaborationStats: null,
        releaseStats: null
    });

    // State for selected file
    const [selectedFile, setSelectedFile] = useState(null);

    // Check if the user has access to the audit
    useEffect(() => {
        if (!auditAccessDetails) {
            console.log("Audit details are not available yet.");
        } else if (auditAccessDetails.error) {
            console.error('Audit access error:', auditAccessDetails.error);
            setShouldRedirect(true);
        } else if (!auditAccessDetails.isPublic && !auditAccessDetails.hasAccess) {
            setShouldRedirect(true);
        } else {
            setShouldRedirect(false);
        }
    }, [auditAccessDetails]);

    // Check audit status
    useEffect(() => {
        const checkStatus = async () => {
            if (!auditUuid) return;
            try {
                await dispatch(checkAuditStatus(auditUuid));
            } catch (error) {
                console.error('Error checking audit status:', error);
            }
        };

        checkStatus();
    }, [auditUuid, dispatch]);

    // Fetch git statistics
    useEffect(() => {
        const fetchData = async () => {
            if (!auditUuid || !auditAccessDetails) return;
            if (!auditAccessDetails.isPublic && !auditAccessDetails.hasAccess) return;

            try {
                setLoading(true);
                // Fetch git statistics
                const gitStatsResponse = await axiosInstance.get(`get_git_statistics/${auditUuid}/`);
                if (gitStatsResponse.data.status === 'success') {
                    const statsData = gitStatsResponse.data.data;
                    setGitStats({
                        timeBasedStats: statsData.time_based_stats,
                        authorStats: statsData.author_stats,
                        codeChurnStats: statsData.code_churn_stats,
                        collaborationStats: statsData.collaboration_stats,
                        releaseStats: statsData.release_stats,
                        branchStats: statsData.branch_stats,
                        mergeStats: statsData.merge_stats,
                        metaInfo: statsData.meta_info,
                        repositoryInfo: statsData.repository_info,
                        developmentAnalysis: statsData.development_analysis,
                        developerExpertise: statsData.developer_expertise
                    });
                } else {
                    console.error('Failed to fetch git statistics:', gitStatsResponse.data.message);
                }

            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };

        if (isDataLoaded) {
            fetchData();
        }
    }, [auditUuid, auditAccessDetails, isDataLoaded]);

    // Add a handler for file clicks
    const handleFileClick = (filename) => {
        const params = new URLSearchParams();
        params.append('search', filename);
        navigate(`/${auditUuid}/files?${params.toString()}`);
    };

    if (shouldRedirect) {
        return <Navigate to="/" />;
    }

    return (
        <PageLayout 
            headerProps={headerProps}
            loading={loading || auditLoading}
            className="development-layout"
        >
            <div className="development-content">
                {!(loading || auditLoading) && (
                    <>
                        {/* Development Summary Section */}
                        <div className="development-summary-card">
                            <div className="development-summary-header">
                                <h2 className="development-section-title">Development Overview</h2>
                                <p className="development-section-subtitle">
                                    Analysis of repository development patterns and team collaboration metrics.
                                </p>
                            </div>
                            {gitStats.developmentAnalysis && (
                                <div className="development-summary-content">
                                    <div className="development-summary-analysis">
                                        <h3>Development Analysis</h3>
                                        <FormattedText text={gitStats.developmentAnalysis} />
                                    </div>
                                </div>
                            )}
                        </div>
                        
                        {/* Time-based Analytics Section */}
                        <div className="development-card">
                            <h3>Development Activity</h3>
                            {gitStats.timeBasedStats && (
                                <>
                                    <div className="development-stats-grid">
                                        <div className="development-stat-item">
                                            <div className="development-stat-label-container">
                                                <span className="development-stat-label">Total Commits</span>
                                                <i className="pi pi-question-circle tooltip-icon" 
                                                   data-pr-position="right"
                                                   data-pr-tooltip={getTooltipDescription('totalCommits')} />
                                            </div>
                                            <span className="development-stat-value">
                                                {formatNumber(gitStats.timeBasedStats.commit_frequency?.total_commits || 0)}
                                            </span>
                                        </div>
                                        <div className="development-stat-item">
                                            <div className="development-stat-label-container">
                                                <span className="development-stat-label">Active Days</span>
                                                <i className="pi pi-question-circle tooltip-icon" 
                                                   data-pr-position="right"
                                                   data-pr-tooltip={getTooltipDescription('activeDays')} />
                                            </div>
                                            <span className="development-stat-value">
                                                {formatNumber(gitStats.timeBasedStats.commit_frequency?.days_active || 0)}
                                            </span>
                                        </div>
                                        <div className="development-stat-item">
                                            <div className="development-stat-label-container">
                                                <span className="development-stat-label">Avg. Commits/Day</span>
                                                <i className="pi pi-question-circle tooltip-icon" 
                                                   data-pr-position="right"
                                                   data-pr-tooltip={getTooltipDescription('avgCommitsDay')} />
                                            </div>
                                            <span className="development-stat-value">
                                                {formatNumber(parseFloat(gitStats.timeBasedStats.commit_frequency?.avg_commits_per_day?.toFixed(2) || 0))}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="development-activity-section">
                                        <h4 className="development-activity-title">Developer Contributions Over Time</h4>
                                        <p className="development-activity-subtitle">
                                            Track the development activity of top contributors and see how their contributions have evolved over time.
                                        </p>
                                        <DevelopmentActivityGraph gitStats={gitStats} auditUuid={auditUuid} />
                                    </div>
                                </>
                            )}
                        </div>

                        {/* Code Churn Section */}
                        <div className="development-card">
                            <h3>Code Changes</h3>
                            <div className="code-changes-container">
                                <div className="code-changes-metrics">
                                    <div className="development-stats-grid">
                                        <div className="development-stat-item">
                                            <div className="development-stat-label-container">
                                                <span className="development-stat-label">Total Additions</span>
                                                <i className="pi pi-question-circle tooltip-icon" 
                                                   data-pr-position="right"
                                                   data-pr-tooltip={getTooltipDescription('totalAdditions')} />
                                            </div>
                                            <span className="development-stat-value additions">
                                                {formatNumber(gitStats?.codeChurnStats?.overall_churn?.total_additions || 0)}
                                            </span>
                                        </div>
                                        <div className="development-stat-item">
                                            <div className="development-stat-label-container">
                                                <span className="development-stat-label">Total Deletions</span>
                                                <i className="pi pi-question-circle tooltip-icon" 
                                                   data-pr-position="right"
                                                   data-pr-tooltip={getTooltipDescription('totalDeletions')} />
                                            </div>
                                            <span className="development-stat-value deletions">
                                                {formatNumber(gitStats?.codeChurnStats?.overall_churn?.total_deletions || 0)}
                                            </span>
                                        </div>
                                        <div className="development-stat-item">
                                            <div className="development-stat-label-container">
                                                <span className="development-stat-label">Modified Files</span>
                                                <i className="pi pi-question-circle tooltip-icon" 
                                                   data-pr-position="right"
                                                   data-pr-tooltip={getTooltipDescription('modifiedFiles')} />
                                            </div>
                                            <span className="development-stat-value">
                                                {formatNumber(gitStats?.codeChurnStats?.file_stability?.total_files_modified || 0)}
                                            </span>
                                        </div>
                                    </div>
                                    
                                    {selectedFile && (
                                        <div className="file-details-container">
                                            <div className="file-details">
                                                <h4>
                                                    <span 
                                                        className="file-name-link"
                                                        onClick={() => handleFileClick(selectedFile.name)}
                                                    >
                                                        {selectedFile.name}
                                                    </span>
                                                </h4>
                                                <div className="file-stats">
                                                    <div className="stat-item">
                                                        <span className="stat-label">Domain</span>
                                                        <span className="stat-value">{selectedFile.domain}</span>
                                                    </div>
                                                    <div className="stat-item">
                                                        <span className="stat-label">Changes</span>
                                                        <span className="stat-value">{selectedFile.stats.total_changes}</span>
                                                    </div>
                                                    <div className="stat-item">
                                                        <span className="stat-label">Modified</span>
                                                        <span className="stat-value">{selectedFile.stats.times_modified} times</span>
                                                    </div>
                                                    <div className="stat-bar">
                                                        <div className="additions" style={{
                                                            width: `${(selectedFile.stats.total_additions / selectedFile.stats.total_changes) * 100}%`
                                                        }}/>
                                                        <div className="deletions" style={{
                                                            width: `${(selectedFile.stats.total_deletions / selectedFile.stats.total_changes) * 100}%`
                                                        }}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div className="code-changes-visualization">
                                    <div className="visualization-description">
                                        <h4>Code Hotspots</h4>
                                        <p>
                                            This visualization shows the most actively modified files in your codebase. 
                                            The size of each bubble represents the file's hotspot score, calculated from 
                                            both the number of changes and frequency of modifications. Colors indicate 
                                            different domains. <strong style={{fontSize: '0.875rem'}}>Click on any bubble to see detailed statistics.</strong>
                                        </p>
                                    </div>
                                    <HotspotsBubbleChart 
                                        hotspots={gitStats?.codeChurnStats?.hotspots?.most_modified_files || []} 
                                        onBubbleSelect={setSelectedFile}
                                    />
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </PageLayout>
    );
}