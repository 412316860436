import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    treeStructure: null,
    selectedKeys: {},
    scopeSummary: {
        numberOfFiles: 0,
        linesOfCode: 0
    },
    auditStatus: null,
    groupAuditUuid: null,
    isLoading: false,
    error: null,
    lastUpdated: null,
    currentAuditUuid: null
};

export const auditScopeSlice = createSlice({
    name: 'auditScope',
    initialState,
    reducers: {
        setTreeStructure: (state, action) => {
            state.treeStructure = action.payload;
            state.lastUpdated = Date.now();
        },
        setSelectedKeys: (state, action) => {
            state.selectedKeys = action.payload;
        },
        setScopeSummary: (state, action) => {
            state.scopeSummary = action.payload;
        },
        setAuditStatus: (state, action) => {
            state.auditStatus = action.payload;
        },
        setGroupAuditUuid: (state, action) => {
            state.groupAuditUuid = action.payload;
        },
        setCurrentAuditUuid: (state, action) => {
            state.currentAuditUuid = action.payload;
        },
        setLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        setError: (state, action) => {
            state.error = action.payload;
        },
        clearAuditScope: (state) => {
            return initialState;
        }
    }
});

export const {
    setTreeStructure,
    setSelectedKeys,
    setScopeSummary,
    setAuditStatus,
    setGroupAuditUuid,
    setCurrentAuditUuid,
    setLoading,
    setError,
    clearAuditScope
} = auditScopeSlice.actions;

// Selectors
export const selectAuditScope = (state) => state.auditScope;
export const selectTreeStructure = (state) => state.auditScope.treeStructure;
export const selectSelectedKeys = (state) => state.auditScope.selectedKeys;
export const selectScopeSummary = (state) => state.auditScope.scopeSummary;
export const selectAuditScopeStatus = (state) => state.auditScope.auditStatus;
export const selectGroupAuditUuid = (state) => state.auditScope.groupAuditUuid;
export const selectIsLoading = (state) => state.auditScope.isLoading;
export const selectError = (state) => state.auditScope.error;
export const selectLastUpdated = (state) => state.auditScope.lastUpdated;
export const selectCurrentAuditUuid = (state) => state.auditScope.currentAuditUuid;

export default auditScopeSlice.reducer; 