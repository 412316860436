import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../axiosConfig';

// Cache duration constants
const CACHE_DURATION = {
    DEFAULT: 30 * 60 * 1000,     // 30 minutes
    MAXIMUM: 24 * 60 * 60 * 1000, // 24 hours
    MINIMUM: 5 * 60 * 1000       // 5 minutes
};

// Helper to determine if cache is valid
const isCacheValid = (cachedStats) => {
    if (!cachedStats?.lastFetched) return false;
    
    const now = new Date().getTime();
    const cacheAge = now - new Date(cachedStats.lastFetched).getTime();
    
    // If we're in development mode, use shorter cache duration
    if (process.env.NODE_ENV === 'development') {
        return cacheAge < CACHE_DURATION.MINIMUM;
    }
    
    // For production, use default cache duration
    return cacheAge < CACHE_DURATION.DEFAULT;
};

// Async thunk for fetching git statistics
export const fetchGitStatistics = createAsyncThunk(
    'development/fetchGitStatistics',
    async (auditUuid, { getState }) => {
        const state = getState();
        const cachedStats = state.development[auditUuid];
        const now = new Date().getTime();

        // Debug logs
        console.debug('Development Cache Check:', {
            auditUuid,
            hasCachedStats: !!cachedStats,
            lastFetched: cachedStats?.lastFetched,
            cacheAge: cachedStats?.lastFetched ? now - new Date(cachedStats.lastFetched).getTime() : 'N/A',
            cacheDuration: process.env.NODE_ENV === 'development' ? 
                CACHE_DURATION.MINIMUM : CACHE_DURATION.DEFAULT,
            isCacheValid: isCacheValid(cachedStats)
        });

        // Return cached data if it's still valid
        if (isCacheValid(cachedStats)) {
            console.debug('Returning cached development data');
            return cachedStats;
        }

        console.debug('Fetching fresh development data');
        const response = await axiosInstance.get(`get_git_statistics/${auditUuid}/`);

        if (response.data.status !== 'success') {
            throw new Error('Failed to fetch git statistics');
        }

        return {
            auditUuid,
            data: response.data.data,
            lastFetched: new Date().toISOString()
        };
    }
);

// Slice for development data
const developmentSlice = createSlice({
    name: 'development',
    initialState: {},
    reducers: {
        clearDevelopmentData: (state, action) => {
            if (action.payload) {
                delete state[action.payload];
            } else {
                return {};
            }
        },
        invalidateCache: (state, action) => {
            if (action.payload && state[action.payload]) {
                delete state[action.payload].lastFetched;
            }
        },
        updateGitStatistics: (state, action) => {
            const { auditUuid, data } = action.payload;
            if (auditUuid) {
                state[auditUuid] = {
                    data,
                    lastFetched: new Date().toISOString()
                };
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchGitStatistics.fulfilled, (state, action) => {
                const { auditUuid, data, lastFetched } = action.payload;
                state[auditUuid] = {
                    data,
                    lastFetched
                };
            })
            .addCase(fetchGitStatistics.rejected, (state, action) => {
                console.error('Failed to fetch git statistics:', action.error);
            });
    }
});

// Export actions
export const { 
    clearDevelopmentData, 
    invalidateCache,
    updateGitStatistics 
} = developmentSlice.actions;

// Selectors
export const selectGitStatistics = (state, auditUuid) => state.development[auditUuid];

export default developmentSlice.reducer;
