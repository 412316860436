import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Chip } from 'primereact/chip';
import AuditScoreBar from '../../user_dashboard/components/AuditScoreBar';
import FileDetailSidebar from '../fileDetailSidebar';
import axiosInstance from '../../axiosConfig';
import { 
    fetchDomains,
    selectDomainsByAuditId,
    selectDomainsLoading,
    selectDomainsError
} from '../../features/domains/domainsSlice';
import {
    fetchFileTypes,
    selectFileTypesByAuditId,
    selectFileTypesLoading,
    selectFileTypesError
} from '../../features/fileTypes/fileTypesSlice';
import './FilesStatistics.css';
import { Paginator } from 'primereact/paginator';
import { Dropdown } from 'primereact/dropdown';
import ScoreDistributionGraph from './ScoreDistributionGraph';
import './ScoreDistributionGraph.css';

const FilesStatistics = () => {
    const { auditUuid } = useParams();
    const location = useLocation();
    const dispatch = useDispatch();
    
    // Domains selectors
    const domainsList = useSelector(state => selectDomainsByAuditId(state, auditUuid));
    const domainsLoading = useSelector(selectDomainsLoading);
    const domainsError = useSelector(selectDomainsError);
    
    // File types selectors
    const fileTypesList = useSelector(state => selectFileTypesByAuditId(state, auditUuid));
    const fileTypesLoading = useSelector(selectFileTypesLoading);
    const fileTypesError = useSelector(selectFileTypesError);
    
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedDomain, setSelectedDomain] = useState(null);
    const [selectedFileType, setSelectedFileType] = useState(null);
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [domains, setDomains] = useState([]);
    const [fileTypes, setFileTypes] = useState([]);
    const [files, setFiles] = useState([]);
    const [loading, setLoading] = useState(true);
    const [sidebarVisible, setSidebarVisible] = useState(false);
    const [selectedFileDetails, setSelectedFileDetails] = useState({});
    const [totalRecords, setTotalRecords] = useState(0);
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(5);
    const [selectedContributor, setSelectedContributor] = useState(null);

    // Add this utility function at the top of the component
    const extractEmail = (contributorString) => {
        const match = contributorString.match(/<([^>]+)>/);
        return match ? match[1] : contributorString;
    };

    // Add new utility function to extract filename
    const extractFilename = (filepath) => {
        if (!filepath) return '';
        // Split by both forward and backward slashes to handle different path formats
        const parts = filepath.split(/[/\\]/);
        return parts[parts.length - 1];
    };

    // Modify the URL parameter effect
    useEffect(() => {
        // Get parameters from URL
        const params = new URLSearchParams(location.search);
        const contributor = params.get('contributor');
        const search = params.get('search');
        console.log('URL Parameters:', Object.fromEntries(params.entries()));
        console.log('Detected contributor from URL:', contributor);
        console.log('Detected search from URL:', search);
        
        // Handle contributor parameter
        if (contributor) {
            const decodedContributor = decodeURIComponent(contributor);
            const email = extractEmail(decodedContributor);
            console.log('Setting contributor state to:', decodedContributor);
            console.log('Extracted email:', email);
            setSelectedContributor(decodedContributor);
        } else {
            console.log('No contributor in URL, clearing contributor state');
            setSelectedContributor(null);
        }

        // Handle search parameter
        if (search) {
            const decodedSearch = decodeURIComponent(search);
            const filename = extractFilename(decodedSearch);
            console.log('Setting search query to:', filename);
            setSearchQuery(filename);
        } else {
            console.log('No search in URL, clearing search query');
            setSearchQuery('');
        }
        
        // Reset other filters when URL changes
        setSelectedDomain(null);
        setSelectedFileType(null);
        setSelectedOrder(null);
        setFirst(0);
    }, [location.search]);

    // Separate useEffect for initial data fetching
    useEffect(() => {
        const fetchInitialData = async () => {
            try {
                // Fetch domains if not in Redux store
                if (!domainsList && !domainsLoading) {
                    await dispatch(fetchDomains(auditUuid)).unwrap();
                }

                // Fetch file types if not in Redux store
                if (!fileTypesList && !fileTypesLoading) {
                    await dispatch(fetchFileTypes(auditUuid)).unwrap();
                }
            } catch (error) {
                console.error('Error fetching initial data:', error);
            }
        };

        fetchInitialData();
    }, [auditUuid, dispatch, domainsList, domainsLoading, fileTypesList, fileTypesLoading]);

    // Separate useEffect for fetching files when filters change
    useEffect(() => {
        const debounceTimer = setTimeout(() => {
            if (!searchQuery || searchQuery.length >= 2) {
                console.log('Triggering fetchFiles due to filter change. Current states:', {
                    contributor: selectedContributor,
                    search: searchQuery,
                    domain: selectedDomain?.domain,
                    fileType: selectedFileType?.extension,
                    order: selectedOrder,
                    first,
                    rows
                });
                fetchFiles();
            }
        }, 300);

        return () => clearTimeout(debounceTimer);
    }, [searchQuery, selectedDomain, selectedFileType, selectedOrder, first, rows, selectedContributor]); // Added selectedContributor to dependencies

    // Update domains list when domainsList changes in Redux
    useEffect(() => {
        if (domainsList) {
            const formattedDomains = domainsList.map(domain => ({
                label: `${domain.domain} - ${domain.count} files`,
                domain: domain.domain,
                count: domain.count
            })).sort((a, b) => b.count - a.count);
            
            setDomains(formattedDomains);
        }
    }, [domainsList]);

    // Update file types list when fileTypesList changes in Redux
    useEffect(() => {
        if (fileTypesList) {
            const formattedFileTypes = fileTypesList.map(fileType => ({
                label: `${fileType.description} - ${fileType.count} files`,
                extension: fileType.extension,
                count: fileType.count,
                description: fileType.description
            })).sort((a, b) => b.count - a.count);
            
            setFileTypes(formattedFileTypes);
        }
    }, [fileTypesList]);

    const fetchFiles = async () => {
        setLoading(true);
        try {
            // Build query parameters
            const params = new URLSearchParams();
            console.log('Starting fetchFiles with states:', {
                contributor: selectedContributor,
                contributorEmail: selectedContributor ? extractEmail(selectedContributor) : null,
                search: searchQuery,
                domain: selectedDomain?.domain,
                fileType: selectedFileType?.extension,
                order: selectedOrder
            });

            // Add all search parameters regardless of contributor
            if (selectedContributor) {
                const email = extractEmail(selectedContributor);
                params.append('contributor', email);
                console.log('Adding contributor email to search params:', email);
            }
            
            if (searchQuery) {
                params.append('search', searchQuery);
            }
            
            if (selectedDomain) {
                params.append('domain', selectedDomain.domain);
            }
            
            if (selectedFileType) {
                params.append('file_type', selectedFileType.extension);
            }
            
            // Order is applicable for all searches
            if (selectedOrder) {
                params.append('order', selectedOrder);
            }

            // Always include pagination parameters
            params.append('offset', first.toString());
            params.append('limit', rows.toString());
            
            const queryString = params.toString();
            console.log('Final search parameters:', queryString);
            console.log(`Making request to: files_search/${auditUuid}/?${queryString}`);
            
            const response = await axiosInstance.get(`files_search/${auditUuid}/?${queryString}`);
            console.log('Search response:', {
                totalResults: response.data.total,
                resultCount: response.data.results.length,
                firstResult: response.data.results[0]
            });
            
            setFiles(response.data.results);
            setTotalRecords(response.data.total);
        } catch (error) {
            console.error('Error in fetchFiles:', error);
            console.error('Error details:', {
                message: error.message,
                response: error.response?.data
            });
        } finally {
            setLoading(false);
        }
    };

    const fetchFileDetails = async (filePath) => {
        try {
            const encodedFilePath = encodeURIComponent(filePath);
            const response = await axiosInstance.get(`api/file_detail/${auditUuid}/${encodedFilePath}/`);
            return response.data;
        } catch (error) {
            console.error('Error fetching file details:', error);
            return null;
        }
    };

    const handleFileClick = async (rowData) => {
        const details = await fetchFileDetails(rowData.file_path);
        if (details) {
            setSelectedFileDetails({
                ...rowData,
                ...details
            });
            setSidebarVisible(true);
        }
    };

    const formatNumber = (number) => {
        return number?.toLocaleString('de-DE') || 'N/A';  // German locale uses dot as thousand separator
    };

    const renderFileName = (rowData) => {
        return (
            <div className="files-statistics-file-name-container">
                <span 
                    className="files-statistics-file-name-link"
                    onClick={() => handleFileClick(rowData)}
                >
                    {rowData.name}
                </span>
                <span className="files-statistics-lines-of-code">
                    lines of code: {formatNumber(rowData.lines_of_code)}
                </span>
            </div>
        );
    };

    const renderScoreBars = (rowData) => {
        return (
            <div className="files-statistics-score-bars-container">
                {rowData.scores && rowData.scores.map((scoreData) => (
                    <div key={scoreData.key} className="files-statistics-score-bar-item">
                        <AuditScoreBar
                            title={scoreData.key}
                            score={scoreData.score}
                            auditId={rowData.id}
                        />
                    </div>
                ))}
            </div>
        );
    };

    const onPageChange = (event) => {
        setFirst(event.first);
        setRows(event.rows);
    };

    const getOrderLabel = (value) => {
        const orderOptions = {
            'loc_desc': 'Most Lines',
            'loc_asc': 'Less Lines',
            'perf_desc': 'Best Performance',
            'perf_asc': 'Worst Performance'
        };
        return orderOptions[value] || '';
    };

    const handleRemoveFilter = (filterType) => {
        console.log('Removing filter:', filterType);
        switch (filterType) {
            case 'contributor':
                console.log('Removing contributor filter, current value:', selectedContributor);
                setSelectedContributor(null);
                // Remove the contributor parameter from URL
                const newUrl = window.location.pathname;
                console.log('Updating URL to:', newUrl);
                window.history.pushState({}, '', newUrl);
                break;
            case 'search':
                setSearchQuery('');
                break;
            case 'domain':
                setSelectedDomain(null);
                break;
            case 'fileType':
                setSelectedFileType(null);
                break;
            case 'order':
                setSelectedOrder(null);
                break;
            default:
                break;
        }
        setFirst(0);
    };

    const renderActiveFilters = () => {
        const activeFilters = [];

        if (selectedContributor) {
            activeFilters.push(
                <Chip
                    key="contributor"
                    label={`Contributor: ${selectedContributor.split('<')[0].trim()}`}
                    removable
                    onRemove={() => handleRemoveFilter('contributor')}
                    className="contributor-chip"
                />
            );
        }

        if (searchQuery) {
            activeFilters.push(
                <Chip 
                    key="search"
                    label={`Search: ${searchQuery}`}
                    removable
                    onRemove={() => handleRemoveFilter('search')}
                />
            );
        }

        if (selectedDomain) {
            activeFilters.push(
                <Chip
                    key="domain"
                    label={`Domain: ${selectedDomain.domain}`}
                    removable
                    onRemove={() => handleRemoveFilter('domain')}
                />
            );
        }

        if (selectedFileType) {
            activeFilters.push(
                <Chip
                    key="fileType"
                    label={`File Type: ${selectedFileType.description}`}
                    removable
                    onRemove={() => handleRemoveFilter('fileType')}
                />
            );
        }

        if (selectedOrder) {
            activeFilters.push(
                <Chip
                    key="order"
                    label={`Order: ${getOrderLabel(selectedOrder)}`}
                    removable
                    onRemove={() => handleRemoveFilter('order')}
                />
            );
        }

        return activeFilters;
    };

    return (
        <div className="files-statistics-container">
            <FileDetailSidebar 
                visible={sidebarVisible} 
                onClose={() => setSidebarVisible(false)} 
                fileDetails={selectedFileDetails}
            />
            <div className="files-statistics-content-wrapper">
                <h2 className="files-statistics-title">File statistics</h2>
                <p className="files-statistics-description">View the audit results for all files based on their evaluation scores.</p>
                <div className="files-statistics-controls">
                    <div className="files-statistics-search-section">
                        <span className="files-statistics-search-wrapper">
                            <InputText
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                                placeholder="Search for files"
                                className="files-statistics-search-input"
                            />
                        </span>
                    </div>

                    <div className="files-statistics-filter-section">
                        <div className="files-statistics-filter-group">
                            <span className="files-statistics-section-label">Filter</span>
                            <div className="files-statistics-filter-controls">
                                <Dropdown
                                    value={selectedDomain}
                                    options={domains}
                                    onChange={(e) => {
                                        setSelectedDomain(e.value);
                                        setFirst(0);
                                    }}
                                    optionLabel="label"
                                    placeholder="Filter by domain"
                                    filter
                                    filterBy="label"
                                    className="files-statistics-filter-dropdown"
                                    emptyMessage="No available options"
                                    emptyFilterMessage="No domains found"
                                />
                                <Dropdown
                                    value={selectedFileType}
                                    options={fileTypes}
                                    onChange={(e) => {
                                        setSelectedFileType(e.value);
                                        setFirst(0);
                                    }}
                                    optionLabel="label"
                                    placeholder="Filter by file type"
                                    filter
                                    filterBy="label"
                                    className="files-statistics-filter-dropdown"
                                    emptyMessage="No available options"
                                    emptyFilterMessage="No file types found"
                                />
                            </div>
                        </div>
                    </div>

                    <div className="files-statistics-order-section">
                        <div className="files-statistics-order-group">
                            <span className="files-statistics-section-label">Order by</span>
                            <Dropdown
                                value={selectedOrder}
                                options={[
                                    { label: 'Most Lines', value: 'loc_desc' },
                                    { label: 'Less Lines', value: 'loc_asc' },
                                    { label: 'Best Performance', value: 'perf_desc' },
                                    { label: 'Worst Performance', value: 'perf_asc' }
                                ]}
                                onChange={(e) => {
                                    console.log('Order selection changed:', e.value);
                                    setSelectedOrder(e.value);
                                    setFirst(0);
                                }}
                                optionLabel="label"
                                className="files-statistics-order-dropdown"
                                placeholder="Select ordering"
                            />
                        </div>
                    </div>
                </div>
                <div className="files-statistics-active-filters">
                    {renderActiveFilters()}
                </div>
                <div className="files-table-container">
                    <DataTable
                        value={files}
                        loading={loading}
                        responsiveLayout="scroll"
                        className="files-statistics-table"
                        rowExpansionTemplate={renderScoreBars}
                        expandedRows={files.map(file => file.id)}
                    >
                        <Column 
                            field="name" 
                            header="File Name" 
                            body={renderFileName} 
                            style={{ width: '30%' }}
                        />
                        <Column 
                            field="scores" 
                            header="Overall Score"
                            body={(rowData) => (
                                <div className="files-statistics-score-bars-row">
                                    {rowData.scores?.map((scoreData) => (
                                        <div key={scoreData.key} className="files-statistics-score-bar-item">
                                            <AuditScoreBar
                                                title={scoreData.key}
                                                score={scoreData.score}
                                                auditId={rowData.id}
                                            />
                                        </div>
                                    ))}
                                </div>
                            )}
                            style={{ width: '70%' }}
                        />
                    </DataTable>
                    <Paginator 
                        first={first} 
                        rows={rows} 
                        totalRecords={totalRecords} 
                        onPageChange={onPageChange}
                        className="files-table-paginator"
                    />
                </div>

                <div className="score-distribution-section">
                    <h2 className="score-distribution-title">Score Distribution</h2>
                    <p className="score-distribution-subtitle">Review the distribution of scores accross all files.</p>
                    <p className="score-distribution-subtitle">You can use the above filters to narrow down the distribution representation.</p>
                    <ScoreDistributionGraph files={files} />
                </div>
            </div>
        </div>
    );
};

export default FilesStatistics; 