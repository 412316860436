// auditDetailsSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../axiosConfig';

// Domain logos mapping
export const domainLogos = {
  "github.com": '/images/GitHub.png',
  "gitlab.com": '/images/GitLab.png',
  "bitbucket.org": "/images/Bitbucket.png",
  "SourceForge.net": "/images/SourceForge.png",
  "code.google.com": "/images/GoogleCode.png",
  "codeplex.com": "/images/CodePlex.png",
  "launchpad.net": "/images/Launchpad.png",
  "savannah.gnu.org": "/images/Savannah.png",
  "freecode.com": "/images/Freecode.png",
  "gitkraken.com": "/images/GitKraken.png",
  "beanstalkapp.com": "/images/Beanstalk.png",
  "assembla.com": "/images/Assembla.png",
  "phabricator.com": "/images/Phabricator.png",
  "gogs.io": "/images/Gogs.png",
  "gitea.com": "/images/Gitea.png",
  "gitbucket.com": "/images/GitBucket.png",
  "codeberg.org": "/images/Codeberg.png",
};

// Async thunk for fetching audit details
export const fetchAuditDetails = createAsyncThunk(
  'auditDetails/fetchAuditDetails',
  async (auditUuid, { rejectWithValue }) => {
    try {
        console.log('Fetching audit details from auditDetailsSlice');
      const response = await axiosInstance.get(`audit_details/${auditUuid}/`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Failed to fetch audit details');
    }
  }
);

// Helper function to extract domain from URL
const extractDomain = (url) => {
  if (!url) return '';
  try {
    const domain = new URL(url).hostname;
    return domain.toLowerCase();
  } catch (error) {
    console.error('Error parsing URL:', error);
    return '';
  }
};

const auditDetailsSlice = createSlice({
  name: 'auditDetails',
  initialState: {
    details: {},
    loading: false,
    error: null,
  },
  reducers: {
    clearAuditDetails: (state) => {
      state.details = {};
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAuditDetails.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAuditDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        // Store the full audit details
        state.details[action.meta.arg] = {
          ...action.payload,
          repoDomain: extractDomain(action.payload.full_url),
          headerProps: {
            auditName: action.payload.name || '',
            fileCount: action.payload.file_count || 0,
            repoUrl: action.payload.full_url || '',
            repoDomain: extractDomain(action.payload.full_url),
            domainLogos,
          }
        };
      })
      .addCase(fetchAuditDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

// Export actions
export const { clearAuditDetails } = auditDetailsSlice.actions;

// Selectors
export const selectAuditDetails = (state, auditUuid) => state.auditDetails.details[auditUuid];
export const selectAuditHeaderProps = (state, auditUuid) => state.auditDetails.details[auditUuid]?.headerProps;
export const selectAuditLoading = (state) => state.auditDetails.loading;
export const selectAuditError = (state) => state.auditDetails.error;

export default auditDetailsSlice.reducer;
