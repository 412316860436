import React from 'react';
import { Tooltip } from 'primereact/tooltip';
import './AttributeStatistics.css';

const scoreMap = {
    "readability": {"Highly Readable": 100, "Moderately Readable": 50, "Low Readability": 0},
    "consistency": {"Highly Consistent": 100, "Somewhat Inconsistent": 50, "Not Consistent": 0},
    "modularity": {"Excellent": 100, "Average": 50, "Poor": 0},
    "maintainability": {"High": 100, "Moderate": 50, "Low": 0},
    "reusability": {"High": 100, "Moderate": 50, "Low": 0},
    "redundancy": {"No Redundancies": 100, "Some Redundancies": 50, "High Redundancy": 0},
    "technical_debt": {"None": 100, "Low": 66, "Moderate": 33, "High": 0},
    "code_smells": {"None": 100, "Low": 66, "Moderate": 33, "High": 0},

    "completeness": {"Fully Functional": 100, "Partially Functional": 50, "Not Functional": 0},
    "edge_cases": {"Excellently Covered": 100, "Partially Covered": 66, "Poorly Covered": 33, "None Covered": 0},
    "error_handling": {"Robust": 100, "Adequate": 50, "Poor": 0},

    "efficiency": {"High": 100, "Average": 50, "Poor": 0},
    "scalability": {"High": 100, "Moderate": 50, "Not Scalable": 0},
    "resource_utilization": {"Optimal": 100, "Acceptable": 50, "Excessive": 0},
    "load_handling": {"Excellent": 100, "Good": 66, "Average": 33, "Poor": 0},
    "parallel_processing": {"Fully Supported": 100, "Partially Supported": 50, "Not Supported": 0},
    "database_interaction_efficiency": {"Optimized": 100, "Sufficient": 50, "Inefficient": 0},
    "concurrency_management": {"Robust": 100, "Adequate": 50, "Poor": 0},
    "state_management_efficiency": {"Optimal": 100, "Adequate": 50, "Problematic": 0},
    "modularity_decoupling": {"Highly Modular": 100, "Somewhat Modular": 50, "Monolithic": 0},
    "configuration_customization_ease": {"Flexible": 100, "Moderate": 50, "Rigid": 0},

    "input_validation": {"Strong": 100, "Adequate": 50, "Weak": 0},
    "data_handling": {"Secure": 100, "Moderately Secure": 50, "Insecure": 0},
    "authentication": {"Robust": 100, "Adequate": 50, "Non-existent": 0},
    "dependencies": {"Updated & Secure": 100, "Some Vulnerabilities": 50, "Outdated & Vulnerable": 0},

    "independence": {"Multi-platform": 100, "Limited Platforms": 50, "Single Platform": 0},
    "integration": {"Seamless": 100, "Requires Workarounds": 50, "Incompatible": 0},

    "inline_comments": {"Comprehensive": 100, "Adequate": 66, "Sparse": 33, "None": 0},

    "standards": {"Fully Compliant": 100, "Partially Compliant": 50, "Non-compliant": 0},
    "design_patterns": {"Extensive": 100, "Moderate": 66, "Rare": 33, "None": 0},
    "code_complexity": {"Low": 100, "Moderate": 50, "High": 0},
    "refactoring_opportunities": {"Many": 100, "Some": 66, "Few": 33, "None": 0},
};

const attributeNameMapping = {
  "Readability": "readability",
  "Consistency": "consistency",
  "Modularity and Maintainability": "modularity",
  "Reusability": "reusability",
  "Redundancy and Technical Debt": "redundancy",
  "Code Smells": "code_smells",
  "Improvement Recommendations": "improvement_recommendations",

  "Completeness": "completeness",
  "Edge Cases": "edge_cases",
  "Error Handling": "error_handling",

  "Efficiency": "efficiency",
  "Scalability": "scalability",
  "Resource Utilization": "resource_utilization",
  "Load Handling": "load_handling",
  "Parallel Processing": "parallel_processing",
  "Database Interaction Efficiency": "database_interaction_efficiency",
  "Concurrency Management": "concurrency_management",
  "State Management Efficiency": "state_management_efficiency",
  "Modularity and Decoupling": "modularity_decoupling",
  "Configuration and Customization Ease": "configuration_customization_ease",

  "Input Validation": "input_validation",
  "Data Handling": "data_handling",
  "Authentication": "authentication",
  "Dependencies": "dependencies",

  "Independence and Flexibility": "independence",
  "Integration Capabilities": "integration",

  "Inline Comments": "inline_comments",

  "Standards Compliance": "standards",
  "Design Patterns": "design_patterns",
  "Code Complexity": "code_complexity",
  "Refactoring Opportunities": "refactoring_opportunities"
};

const AttributeStatistics = ({ attributeName, statistics }) => {
  
  // Map the display name to the backend name
  const backendAttributeName = attributeNameMapping[attributeName] || attributeName.toLowerCase().replace(/ /g, '_');
  
  if (!statistics || !statistics[backendAttributeName]) {
    return null;
  }

  // Helper function to get color based on score
  const getColorClass = (value) => {
    const attributeNameLower = backendAttributeName.toLowerCase();
    const valueLower = value.toLowerCase();
    
    const scores = scoreMap[attributeNameLower];
    if (!scores) {
      return 'stat-grey';
    }

    // Find the matching score by comparing lowercase values
    const score = scores[Object.keys(scores).find(key => key.toLowerCase() === valueLower)];
    if (score === undefined) return 'stat-grey';
    if (score >= 66) return 'stat-green';
    if (score >= 33) return 'stat-orange';
    return 'stat-red';
  };

  // Helper function to get score for tooltip
  const getScore = (value) => {
    const attributeNameLower = backendAttributeName.toLowerCase();
    const valueLower = value.toLowerCase();
    const scores = scoreMap[attributeNameLower];
    if (!scores) return null;
    
    const score = scores[Object.keys(scores).find(key => key.toLowerCase() === valueLower)];
    return score !== undefined ? score : null;
  };

  // Sort values based on their scores in the map
  const sortedStats = Object.entries(statistics[backendAttributeName])
    .sort(([valueA], [valueB]) => {
      const scoresForAttr = scoreMap[backendAttributeName.toLowerCase()] || {};
      const scoreA = scoresForAttr[Object.keys(scoresForAttr).find(key => key.toLowerCase() === valueA.toLowerCase())] || 0;
      const scoreB = scoresForAttr[Object.keys(scoresForAttr).find(key => key.toLowerCase() === valueB.toLowerCase())] || 0;
      return scoreB - scoreA;
    });

  return (
    <div className="attribute-statistics">
      <span className="stat-label">Info:</span>
      {sortedStats.map(([value, count], index) => {
        const score = getScore(value);
        // Replace spaces with dashes for valid HTML ID
        const tooltipId = `${backendAttributeName}-${value.replace(/\s+/g, '-')}-${index}`;
        
        return (
          <React.Fragment key={value}>
            <span className="stat-count">{count}</span> {count === 1 ? 'file' : 'files'} marked as{' '}
            <span 
              className={`stat-value ${getColorClass(value)}`}
              data-pr-tooltip={score !== null ? `${score} points` : undefined}
              data-pr-position="top"
              data-pr-at="center+2 top-2"
              id={tooltipId}
            >
              {value}
            </span>
            {index < sortedStats.length - 1 && <span className="stat-separator">, </span>}
            {score !== null && <Tooltip target={`#${tooltipId}`} />}
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default AttributeStatistics;