import React, { useRef, useState } from 'react';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { ProgressSpinner } from 'primereact/progressspinner';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { selectAuth } from '../features/user/userSlice';
import { useCsrfToken } from '../utils/CsrfTokenContext';
import { useNavigate } from 'react-router-dom';

import './ExportData.css';

const ExportAuditResults = ({ closeModal, auditUuid }) => {
    const [isLoadingFiles, setIsLoadingFiles] = useState(false);
    const [isLoadingDeps, setIsLoadingDeps] = useState(false);
    const toast = useRef(null);
    const { csrfToken } = useCsrfToken();
    const { accountUuid } = useSelector(selectAuth);
    const navigate = useNavigate();

    /**
     * Shows an error toast with optional action button
     * @param {string} message - The error message to display
     * @param {boolean} requiresLogin - Whether the error requires user to login
     */
    const showErrorToast = (message, requiresLogin = false) => {
        toast.current.show({
            severity: 'error',
            summary: 'Error',
            detail: message,
            life: 6000,
            closable: true,
            sticky: requiresLogin,
            content: requiresLogin ? (
                <div className="flex flex-column">
                    <div className="text-red-500 mb-3">
                        <i className="pi pi-exclamation-circle mr-2" />
                        {message}
                    </div>
                    <Button
                        label="Login"
                        className="p-button-sm"
                        onClick={() => {
                            navigate('/login', { 
                                state: { 
                                    returnUrl: window.location.pathname,
                                    message: 'Please log in to download audit results.' 
                                } 
                            });
                        }}
                    />
                </div>
            ) : undefined
        });
    };

    /**
     * Validates the current session state
     * @returns {boolean} Whether the session is valid
     */
    const validateSession = () => {
        if (!csrfToken) {
            showErrorToast('Your session has expired. Please log in again to download the file.', true);
            return false;
        }

        if (!accountUuid) {
            showErrorToast('Account verification failed. Please log in again.', true);
            return false;
        }

        if (!auditUuid) {
            showErrorToast('Invalid audit reference. Please try accessing this page again.');
            return false;
        }

        return true;
    };

    /**
     * Maps HTTP error codes to user-friendly messages
     * @param {number} status - HTTP status code
     * @param {string} defaultMessage - Default message if status isn't mapped
     * @returns {Object} Error message and whether it requires login
     */
    const getErrorDetails = (status, defaultMessage) => {
        const errorMap = {
            400: {
                message: 'The download request was invalid. Please try again.',
                requiresLogin: false
            },
            401: {
                message: 'Your session has expired. Please log in again to download the file.',
                requiresLogin: true
            },
            403: {
                message: 'You don\'t have permission to download this file. Please log in with an authorized account.',
                requiresLogin: true
            },
            404: {
                message: 'The requested file could not be found. It may have been deleted or moved.',
                requiresLogin: false
            },
            429: {
                message: 'Too many download attempts. Please wait a few minutes and try again.',
                requiresLogin: false
            },
            500: {
                message: 'The server encountered an error while preparing your download. Please try again later.',
                requiresLogin: false
            },
            502: {
                message: 'The download service is temporarily unavailable. Please try again later.',
                requiresLogin: false
            },
            503: {
                message: 'The system is under maintenance. Please try again in a few minutes.',
                requiresLogin: false
            }
        };

        return errorMap[status] || {
            message: defaultMessage,
            requiresLogin: false
        };
    };

    /**
     * Handles file download by creating a temporary link and triggering download
     * @param {Blob} blob - The file blob to download
     * @param {string} filename - The name for the downloaded file
     */
    const downloadFile = (blob, filename) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        
        try {
            link.href = url;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
        } catch (error) {
            console.error('Download error:', error);
            showErrorToast('Failed to initiate download. Please try again.');
        } finally {
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
        }
    };

    /**
     * Initiates the file download process with proper error handling
     * @param {string} type - The type of export being requested
     */
    const handleDownload = async (type) => {
        if (!validateSession()) {
            return;
        }
    
        // Set loading state based on type
        if (type === 'dependencies') {
            setIsLoadingDeps(true);
        } else {
            setIsLoadingFiles(true);
        }
    
        try {
            // Select endpoint based on export type
            const endpoint = type === 'dependencies' 
                ? `${process.env.REACT_APP_API_URL}download_dependencies_csv/${auditUuid}/`
                : `${process.env.REACT_APP_API_URL}download_file_csv/${auditUuid}/`;

            const response = await axios({
                method: 'get',
                url: endpoint,
                headers: {
                    'X-CSRFToken': csrfToken,
                    'Content-Type': 'application/json',
                    'Accept': '*/*',
                },
                withCredentials: true,
                responseType: 'blob'
            });
    
            // Get filename from content disposition header
            const contentDisposition = response.headers['content-disposition'];
            const filename = contentDisposition
                ? contentDisposition.split('filename=')[1]?.replace(/['"]/g, '')
                : type === 'dependencies'
                    ? `package-dependencies-${auditUuid}-${new Date().toISOString().split('T')[0]}.csv`
                    : `audit-files-${auditUuid}-${new Date().toISOString().split('T')[0]}.csv`;
    
            // Use the downloadFile function to handle the download
            downloadFile(response.data, filename);
    
            toast.current.show({
                severity: 'success',
                summary: 'Success',
                detail: 'File downloaded successfully',
                life: 3000
            });
        } catch (error) {
            console.error('Download error:', error);
            
            if (error.response) {
                const { status } = error.response;
                const { message, requiresLogin } = getErrorDetails(
                    status,
                    'An unexpected error occurred while downloading the file'
                );
                showErrorToast(message, requiresLogin);
            } else if (error.code === 'ECONNABORTED') {
                showErrorToast('The download request timed out. Please check your connection and try again.');
            } else if (!navigator.onLine) {
                showErrorToast('No internet connection. Please check your connection and try again.');
            } else {
                showErrorToast('An unexpected error occurred. Please try again or contact support if the issue persists.');
            }
        } finally {
            // Clear loading state based on type
            if (type === 'dependencies') {
                setIsLoadingDeps(false);
            } else {
                setIsLoadingFiles(false);
            }
        }
    };

    return (
        <div className="export-audit-results">
            <Toast ref={toast} position="top-right" />
            
            <div className="downloads-container">
                {/* Files Section */}
                <div className="download-section">
                    <div className="flex align-items-center p-4 border-1 surface-border border-round mb-3">
                        <div className="excel-icon-container">
                            <img src="/images/app/excel.webp" alt="Excel file" className="excel-icon" />
                        </div>
                        <div className="download-section-content">
                            <div className="text-2xl font-strong">Files</div>
                            <div className="text-500">Download a CSV with a complete review of all files</div>
                        </div>
                        <Button 
                            label="Download CSV"
                            icon={isLoadingFiles ? 'pi pi-spin pi-spinner' : "pi pi-download"}
                            onClick={() => handleDownload('files')}
                            disabled={isLoadingFiles}
                            className="export-button"
                            iconPos="left"
                        />
                    </div>

                    {/* Dependencies Section */}
                    <div className="flex align-items-center p-4 border-1 surface-border border-round">
                        <div className="excel-icon-container">
                            <img src="/images/app/excel.webp" alt="Excel file" className="excel-icon" />
                        </div>
                        <div className="download-section-content">
                            <div className="text-2xl font-strong">Dependencies</div>
                            <div className="text-500">Download a CSV with a complete review of all dependencies</div>
                        </div>
                        <Button 
                            label="Download CSV"
                            icon={isLoadingFiles ? 'pi pi-spin pi-spinner' : "pi pi-download"}
                            onClick={() => handleDownload('files')}
                            disabled={isLoadingFiles}
                            className="export-button"
                            iconPos="left"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ExportAuditResults;