// store.js
import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { combineReducers } from 'redux';
import userReducer from './features/user/userSlice';
import auditsReducer from './features/audits/auditsSlice';
import auditDetailsReducer from './features/audits/auditDetailsSlice';
import domainsReducer from './features/domains/domainsSlice';
import fileTypesReducer from './features/fileTypes/fileTypesSlice';
import developmentReducer from './features/audits/developmentSlice';
import auditScopeReducer from './features/auditScope/auditScopeSlice';

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['user', 'audits', 'auditDetails', 'domains', 'fileTypes', 'development'],
    blacklist: ['auditScope'],
    version: 1,
    timeout: 2000,
    stateReconciler: (inboundState, originalState, reducedState) => {
        // If we have valid tokens in localStorage but not in the inbound state,
        // prefer the localStorage tokens
        if (!inboundState?.user?.accessToken && localStorage.getItem('accessToken')) {
            return {
                ...reducedState,
                user: {
                    ...reducedState.user,
                    accessToken: localStorage.getItem('accessToken'),
                    refreshToken: localStorage.getItem('refreshToken'),
                    accountUuid: localStorage.getItem('accountUuid'),
                    accountName: localStorage.getItem('accountName'),
                    isAdmin: localStorage.getItem('isAdmin') === 'true',
                    isLoggedIn: true
                }
            };
        }
        
        return {
            ...reducedState,
            ...inboundState
        };
    }
};

// Create separate persist config for auditScope with specific blacklist
const auditScopePersistConfig = {
    key: 'auditScope',
    storage,
    blacklist: ['treeStructure', 'selectedKeys'],
};

const rootReducer = combineReducers({
    user: userReducer,
    audits: auditsReducer,
    auditDetails: auditDetailsReducer,
    domains: domainsReducer,
    fileTypes: fileTypesReducer,
    development: developmentReducer,
    auditScope: persistReducer(auditScopePersistConfig, auditScopeReducer),
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [
                    FLUSH,
                    REHYDRATE,
                    PAUSE,
                    PERSIST,
                    PURGE,
                    REGISTER,
                    'persist/PERSIST',
                    'persist/REHYDRATE'
                ],
                ignoredPaths: ['register.timestamp'],
            },
        }),
});

// Add store to window for debugging
if (process.env.NODE_ENV === 'development') {
    window.store = store;
}

// Subscribe to store changes
store.subscribe(() => {
    const state = store.getState();
    const { user } = state;
    
    // Only update localStorage if we have valid auth data
    if (user.accessToken && user.refreshToken) {
        const currentLocalStorage = {
            accessToken: localStorage.getItem('accessToken'),
            refreshToken: localStorage.getItem('refreshToken'),
            accountUuid: localStorage.getItem('accountUuid')
        };

        // Only update if the values are different
        if (currentLocalStorage.accessToken !== user.accessToken ||
            currentLocalStorage.refreshToken !== user.refreshToken ||
            currentLocalStorage.accountUuid !== user.accountUuid) {
            
            localStorage.setItem('accessToken', user.accessToken);
            localStorage.setItem('refreshToken', user.refreshToken);
            localStorage.setItem('accountUuid', user.accountUuid);
            localStorage.setItem('accountName', user.accountName);
            localStorage.setItem('isAdmin', user.isAdmin);
        }
    }
});

export const persistor = persistStore(store);

export default store;