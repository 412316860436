import axios from 'axios';
import store from './store'; 
import { logout, updateAccessToken } from './features/user/userSlice';
import { PUBLIC_ENDPOINTS, getAuditUuidFromPath } from './utils/routeUtils';

// Create a single axios instance with consistent config
const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    withCredentials: true,  // Maintain credentials for CORS
    headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }
});

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
    failedQueue.forEach(prom => {
        if (error) {
            prom.reject(error);
        } else {
            prom.resolve(token);
        }
    });
    failedQueue = [];
};

// Function to handle logout
const handleLogout = () => {
    if (!isRefreshing) {
        isRefreshing = true;
        
        // Clear all auth data
        store.dispatch(logout());
        
        // Process any queued requests
        processQueue(new Error('Auth failed'));
        
        // Redirect to login with session expired parameter
        window.location.replace('/login?session_expired=true');
        
        // Reset the refreshing flag
        setTimeout(() => {
            isRefreshing = false;
        }, 100);
    }
};

// Add a method to handle CSRF token
let storedCsrfToken = null;
export const updateCsrfToken = (token) => {
    if (token) {
        storedCsrfToken = token;
        axiosInstance.defaults.headers.common['X-CSRFToken'] = token;
    }
};

// Request interceptor
axiosInstance.interceptors.request.use(
    async (config) => {
        // Add CSRF token if available
        const csrfToken = storedCsrfToken || document.cookie.split('; ')
            .find(row => row.startsWith('csrftoken='))
            ?.split('=')[1];
            
        if (csrfToken) {
            config.headers['X-CSRFToken'] = csrfToken;
        }

        // Normalize the URL for checking
        const normalizedUrl = config.url.startsWith('/') ? config.url : `/${config.url}`;

        // List of endpoints that should never have auth token
        const noAuthEndpoints = [
            'refresh_token/',
            'set_csrf_token/',
            'login/',
            'register/',
            'validate_pass_key/',
            'resend_pass_key/',
            'reset-password/',
            'confirm-password-reset/',
            'validate-reset-token/',
            'is_uuid_path_public/',
            'public_audits/',
            'search_public_audits/',
            'audit_badge/',
        ];

        // Check if this is a no-auth endpoint
        const isNoAuthEndpoint = noAuthEndpoints.some(endpoint => 
            normalizedUrl.includes(endpoint)
        );

        // For no-auth endpoints, ensure no Authorization header is present
        if (isNoAuthEndpoint) {
            config.headers = {
                ...config.headers,
                'Authorization': undefined
            };
            return config;
        }

        // Check if this is a public endpoint
        const isPublicEndpoint = PUBLIC_ENDPOINTS.some(endpoint => 
            normalizedUrl.includes(endpoint)
        );

        // Special handling for is_uuid_path_public endpoint
        if (normalizedUrl.includes('is_uuid_path_public/')) {
            return config;
        }

        // Extract audit UUID from URL if present
        const auditUuid = getAuditUuidFromPath(config.url);
        
        if (auditUuid) {
            // Get cached audit status from Redux store
            const cachedAudit = store.getState().audits[auditUuid];
            if (cachedAudit?.isPublic) {
                // If we know it's public from cache, allow request
                return config;
            }
        }

        // Get current tokens from localStorage
        const accessToken = localStorage.getItem('accessToken');
        const refreshToken = localStorage.getItem('refreshToken');

        // If no tokens available and not a public endpoint, redirect to login
        if (!accessToken && !refreshToken && !isPublicEndpoint) {
            if (!config._retry && auditUuid) {
                try {
                    if (config._checkingPublic) {
                        return config;
                    }
                    
                    const response = await axiosInstance.get('is_uuid_path_public/', {
                        params: { audit_uuid: auditUuid },
                        _retry: true,
                        _checkingPublic: true
                    });
                    if (response.data.publicity) {
                        return config;
                    }
                } catch (error) {
                    console.error('Error checking audit publicity:', error);
                    handleLogout();
                    return Promise.reject('Authentication required');
                }
            }
            handleLogout();
            return Promise.reject('No authentication token');
        }

        // Add Authorization header if we have an access token
        if (accessToken) {
            config.headers.Authorization = `Bearer ${accessToken}`;
        }

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// Response interceptor
axiosInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    async (error) => {
        const originalRequest = error.config;

        // Check if error is due to token expiration
        const isTokenExpiredError = 
            error.response?.status === 401 && 
            (error.response?.data?.code === 'token_not_valid' ||
             error.response?.data?.detail === 'Given token not valid for any token type');

        if (isTokenExpiredError) {
            // If this is a refresh token request that failed, handle logout
            if (originalRequest.url.includes('refresh_token')) {
                handleLogout();
                return Promise.reject(error);
            }

            // Don't retry if this is already a retry
            if (originalRequest._retry) {
                handleLogout();
                return Promise.reject(error);
            }

            if (isRefreshing) {
                try {
                    await new Promise((resolve, reject) => {
                        failedQueue.push({ resolve, reject });
                    });
                    return axiosInstance(originalRequest);
                } catch (err) {
                    handleLogout();
                    return Promise.reject(err);
                }
            }

            originalRequest._retry = true;
            isRefreshing = true;

            try {
                const refreshToken = localStorage.getItem('refreshToken');
                if (!refreshToken) {
                    throw new Error('No refresh token available');
                }

                const response = await axios.post(`${process.env.REACT_APP_API_URL}refresh_token/`, {
                    refresh: refreshToken
                });

                if (response.data.status === 'success' && response.data.access) {
                    const { access } = response.data;
                    localStorage.setItem('accessToken', access);
                    store.dispatch(updateAccessToken({ access }));
                    
                    // Process all queued requests
                    processQueue(null, access);
                    
                    // Update the original request with new token
                    originalRequest.headers['Authorization'] = `Bearer ${access}`;
                    return axiosInstance(originalRequest);
                } else {
                    throw new Error('Failed to refresh token');
                }
            } catch (err) {
                processQueue(err, null);
                handleLogout();
                return Promise.reject(err);
            } finally {
                isRefreshing = false;
            }
        }

        return Promise.reject(error);
    }
);

export default axiosInstance;
