import { useState, useEffect, useRef } from 'react';
import { Card } from 'primereact/card';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Button } from 'primereact/button';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setUserAuthInfo } from '../../features/user/userSlice';
import { useCsrfToken } from '../../utils/CsrfTokenContext';
import { Message } from 'primereact/message';
import axiosInstance from '../../axiosConfig';
import { Toast } from 'primereact/toast';

import './Login.css';


const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [inputComplete, setInputComplete] = useState(false);
    const [apiError, setApiError] = useState('');
    const emailRef = useRef(null);
    const passwordRef = useRef(null);
    const loginButtonRef = useRef(null);
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const { refreshCsrfToken } = useCsrfToken();
    const toast = useRef(null);

    const validateEmail = (email) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return email === '' || regex.test(email);
    };

    const validatePassword = (password) => {
        return password.length >= 8;
    };

    const validateForm = () => {
        return email !== '' && validateEmail(email) && validatePassword(password) && Object.keys(errors).length === 0;
    };    

    useEffect(() => {
        setInputComplete(validateForm());
    }, [email, password, errors]);

    const handleInputChange = (field, value) => {
        let newErrors = { ...errors };
        delete newErrors[field];
        delete newErrors.api;

        switch (field) {
            case 'email':
                setEmail(value);
                if (!validateEmail(value) && value !== '') {
                    newErrors.email = 'Invalid email address';
                }
                break;
            case 'password':
                setPassword(value);
                if (!validatePassword(value) && value !== '') {
                    newErrors.password = 'Password must be at least 8 characters long';
                }
                break;
            default:
                break;
        }

        setErrors(newErrors);
    };

    const handleSubmit = async () => {
        if (validateForm()) {
            setIsLoading(true);
            setApiError(''); // Clear any previous API errors
            try {
                // First ensure we have a fresh CSRF token
                await refreshCsrfToken();

                const response = await axiosInstance.post(`login/`, {
                    email,
                    password
                });
            
                // Check if the response indicates an error
                if (response.data.status === 'error') {
                    setApiError(response.data.message || 'Login failed. Please check your credentials.');
                    return; // Stop execution here
                }

                if (response.data.status === 'success') {
                    // Update Redux state first
                    dispatch(setUserAuthInfo({ 
                        access: response.data.access,
                        refresh: response.data.refresh,
                        accountUuid: response.data.account_uuid,
                        accountName: response.data.account_name,
                        isAdmin: response.data.is_admin
                    }));

                    // Update axios instance authorization header
                    axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${response.data.access}`;
                    
                    // Get a fresh CSRF token after login
                    await refreshCsrfToken();

                    // Verify the token is working
                    try {
                        const verifyResponse = await axiosInstance.get(`verify_auth/`);
                        if (!verifyResponse.data.authenticated) {
                            throw new Error('Authentication verification failed');
                        }

                        const from = location.state?.from?.pathname || '/dashboard';
                        navigate(from, { replace: true });
                    } catch (verifyError) {
                        console.error('Verification error:', verifyError);
                        setApiError('Failed to verify authentication. Please try again.');
                        // Clean up any set auth data
                        localStorage.clear();
                        dispatch(setUserAuthInfo({
                            access: null,
                            refresh: null,
                            accountUuid: null,
                            accountName: null,
                            isAdmin: false
                        }));
                    }
                }
            } catch (error) {
                console.error('Login error details:', {
                    status: error.response?.status,
                    data: error.response?.data,
                    message: error.message
                });
                
                // Handle specific error cases
                if (error.response?.status === 401) {
                    setApiError('Invalid email or password. Please try again.');
                    return; // Stop execution here
                } else if (error.response?.status === 403) {
                    setApiError('Your account is not authorized to access this application. Please contact your administrator.');
                    return; // Stop execution here
                } else if (error.response?.status === 429) {
                    setApiError('Too many requests. Please try again later.');
                    return; // Stop execution here
                } else {
                    setApiError(error.response?.data?.message || 'An unexpected error occurred. Please try again.');
                }
                
                // Clean up any set auth data
                localStorage.clear();
                dispatch(setUserAuthInfo({
                    access: null,
                    refresh: null,
                    accountUuid: null,
                    accountName: null,
                    isAdmin: false
                }));
            } finally {
                setIsLoading(false);  
            }
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && inputComplete && !isLoading) {
            handleSubmit();
        }
    };

    useEffect(() => {
        // Check for session_expired parameter
        const params = new URLSearchParams(location.search);
        if (params.get('session_expired') === 'true' && toast.current) {
            toast.current.show({
                severity: 'warn',
                summary: 'Session Expired',
                detail: 'Your session has expired. Please login again.',
                life: 5000
            });
            
            // Clean up the URL
            window.history.replaceState({}, '', '/login');
        }
    }, [location]);

    return (
        <div className="login-container" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Toast ref={toast} position="top-right" />
            <div className="login-error-container" style={{ 
                marginBottom: '2rem', 
                width: '100%', 
                maxWidth: '450px',
                display: 'flex',
                justifyContent: 'center',
                Height: '80px', // Reserve space for error message
                visibility: apiError ? 'visible' : 'hidden', // Hide but maintain space
                opacity: apiError ? 1 : 0, // Fade in/out
                transition: 'opacity 0.3s ease' // Smooth transition
            }}>
                {apiError && (
                    <Message 
                        severity="error" 
                        text={apiError} 
                        style={{ width: '100%' }}
                        className="error-message"
                    />
                )}
            </div>
            <Card className="login-card" style={{ width: '100%', maxWidth: '450px' }}>
                <div className="login-header">
                    <img 
                        src="/logo192.png" 
                        style={{ width: '50px', height: '50px', verticalAlign: 'middle'}} 
                        alt="CodeDD Logo" 
                    />
                    <h1>Log in to CodeDD</h1>
                </div>
                <div className="p-fluid">
                    <div className="p-field">
                        <span className="p-float-label">
                            <InputText
                                id="email"
                                value={email}
                                onChange={(e) => handleInputChange('email', e.target.value)}
                                onKeyDown={handleKeyDown}
                                className={errors.email ? 'p-invalid' : ''}
                                ref={emailRef}
                            />
                            <label htmlFor="email">Email</label>
                        </span>
                        {errors.email && <small className="p-error">{errors.email}</small>}
                    </div>
                    <div className="p-field">
                        <span className="p-float-label">
                            <Password
                                id="password"
                                value={password}
                                onChange={(e) => handleInputChange('password', e.target.value)}
                                onKeyDown={handleKeyDown}
                                className={errors.password ? 'p-invalid' : ''}
                                feedback={false}
                                ref={passwordRef}
                                toggleMask
                            />
                            <label htmlFor="password">Password</label>
                        </span>
                        {errors.password && <small className="p-error">{errors.password}</small>}
                    </div>
                    <Button
                        label={
                            <span style={{ color: 'white', fontSize: '1rem' }}>
                              {isLoading ? "Logging in..." : "Log in"}
                            </span>
                        }
                        className="login-page-button"
                        onClick={handleSubmit}
                        disabled={isLoading || !inputComplete}
                        ref={loginButtonRef}
                    />
                    <div className="forgot-password">
                        <a href="/forgot-password">Did you forget your password?</a>
                    </div>
                    <p className="terms-text">
                        By clicking "Log in" you accept the <a href="/terms">CodeDD Terms of Use</a> and acknowledge the <a href="/privacy-statement">Privacy Statement</a> and <a href="/data-processing-addendum">Data Processing Addendum</a>.
                    </p>
                </div>
            </Card>
        </div>
    );
};

export default Login;