import React, { useState, useEffect, useRef } from 'react';
import { Card } from 'primereact/card';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import axiosInstance from '../../axiosConfig';
import { useSelector } from 'react-redux';
import './ProfileCard.css';

const ProfileCard = () => {
    const [profileData, setProfileData] = useState({
        fullName: '',
        email: '',
        workFunction: ''
    });
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const accountUuid = useSelector(state => state.user.accountUuid);
    const toast = useRef(null);

    const workFunctionOptions = [
        { label: 'Investor / Business Angel', value: 'investor' },
        { label: 'Venture Capital', value: 'venture_capital' },
        { label: 'Private Equity', value: 'private_equity' },
        { label: 'Mergers and Acquisitions', value: 'ma' },
        { label: 'Startup (CEO / CTO)', value: 'startup' },
        { label: 'Engineering', value: 'engineering' },
        { label: 'Legal', value: 'legal' },
        { label: 'Human Resources', value: 'hr' },
        { label: 'Procurement', value: 'procurement' },
        { label: 'Other', value: 'other' }
    ];

    useEffect(() => {
        fetchProfileData();
    }, [accountUuid]);

    const fetchProfileData = async () => {
        if (!accountUuid) return;

        setLoading(true);
        try {
            const response = await axiosInstance.get(`get_profile_data/${accountUuid}/`);
            setProfileData({
                fullName: response.data.name || '',
                email: response.data.email || '',
                workFunction: response.data.work_function || ''
            });
        } catch (error) {
            console.error('Error fetching profile data:', error);
            toast.current.show({
                severity: 'error',
                summary: 'Error',
                detail: 'Failed to load profile data',
                life: 3000
            });
        } finally {
            setLoading(false);
        }
    };

    const validateForm = () => {
        const newErrors = {};
        
        if (!profileData.fullName || profileData.fullName.trim().length < 2) {
            newErrors.fullName = 'Name must be at least 2 characters long';
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSave = async () => {
        if (!accountUuid) return;

        if (!validateForm()) {
            toast.current.show({
                severity: 'warn',
                summary: 'Validation Error',
                detail: 'Please check the form for errors',
                life: 3000
            });
            return;
        }

        setLoading(true);
        try {
            await axiosInstance.post(`update_profile_data/${accountUuid}/`, {
                name: profileData.fullName.trim(),
                work_function: profileData.workFunction
            });
            setIsEditing(false);
            toast.current.show({
                severity: 'success',
                summary: 'Success',
                detail: 'Profile updated successfully',
                life: 3000
            });
        } catch (error) {
            console.error('Error updating profile data:', error);
            toast.current.show({
                severity: 'error',
                summary: 'Error',
                detail: 'Failed to update profile',
                life: 3000
            });
        } finally {
            setLoading(false);
        }
    };

    const handleCancel = () => {
        setIsEditing(false);
        setErrors({});
        fetchProfileData(); // Reset to original data
    };

    const handleNameChange = (e) => {
        const value = e.target.value;
        setProfileData({ ...profileData, fullName: value });
        
        // Clear error when user starts typing valid input
        if (value.trim().length >= 2 && errors.fullName) {
            setErrors({ ...errors, fullName: undefined });
        }
    };

    const renderContent = () => {
        if (isEditing) {
            return (
                <div className="profile-edit-form">
                    <div className="p-field">
                        <label htmlFor="fullName">Full name</label>
                        <InputText
                            id="fullName"
                            value={profileData.fullName}
                            onChange={handleNameChange}
                            className={`w-full ${errors.fullName ? 'p-invalid' : ''}`}
                        />
                        {errors.fullName && (
                            <small className="p-error">{errors.fullName}</small>
                        )}
                    </div>
                    <div className="p-field">
                        <label htmlFor="email">Email</label>
                        <InputText
                            id="email"
                            value={profileData.email}
                            disabled
                            className="w-full"
                        />
                    </div>
                    <div className="p-field">
                        <label htmlFor="workFunction">What best describes your work?</label>
                        <Dropdown
                            id="workFunction"
                            value={profileData.workFunction}
                            options={workFunctionOptions}
                            onChange={(e) => setProfileData({ ...profileData, workFunction: e.value })}
                            className="w-full"
                            placeholder="Select a work function"
                        />
                    </div>
                    <div className="profile-actions">
                        <Button 
                            label="Cancel" 
                            className="p-button-text" 
                            onClick={handleCancel}
                            disabled={loading}
                        />
                        <Button 
                            label="Save changes" 
                            onClick={handleSave}
                            loading={loading}
                        />
                    </div>
                </div>
            );
        }

        return (
            <div className="profile-view">
                <div className="profile-field">
                    <span className="profile-label">Full name</span>
                    <span className="profile-value">{profileData.fullName}</span>
                </div>
                <div className="profile-field">
                    <span className="profile-label">Email</span>
                    <span className="profile-value">{profileData.email}</span>
                </div>
                <div className="profile-field">
                    <span className="profile-label">Work function</span>
                    <span className="profile-value">
                        {workFunctionOptions.find(opt => opt.value === profileData.workFunction)?.label || 'Not specified'}
                    </span>
                </div>
                <div className="profile-actions">
                    <Button 
                        label="Edit profile" 
                        className="p-button-text" 
                        onClick={() => setIsEditing(true)}
                        disabled={loading}
                    />
                </div>
            </div>
        );
    };

    return (
        <Card className="profile-card">
            <Toast ref={toast} />
            <div className="profile-header">
                <h2>Profile</h2>
                <p className="profile-subtitle">Manage your personal information</p>
            </div>
            {renderContent()}
        </Card>
    );
};

export default ProfileCard; 