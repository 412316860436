import React from 'react';
import './LoadingAnimation.css';

const LoadingAnimation = () => {
  return (
    <div className="loading-animation-container">
      <div className="loading-animation">
        <svg viewBox="0 0 80 80" xmlns="http://www.w3.org/2000/svg">
          {/* Exact C shape from the logo */}
          <path 
            className="cls-1" 
            d="M47.09,64.68c-2.24.62-4.61.94-7.05.92-13.94-.15-25.22-11.67-25.16-25.69.05-14.09,11.42-25.5,25.44-25.5,2.35,0,4.62.32,6.77.92.32.09.64-.15.64-.49V1.11c0-.24-.17-.45-.41-.49C45.15.23,42.92.02,40.64,0,18.73-.17.56,17.95.56,40c0,22.09,17.8,40,39.76,40,2.39,0,4.72-.21,6.99-.62.24-.04.42-.26.42-.51v-13.71c0-.34-.32-.58-.64-.49Z"
            fill="#32AFC3"
          />
          {/* Three dots positioned horizontally */}
          <circle className="logo-dot-1" cx="50" cy="40" r="7" fill="#E83636"/>
          <circle className="logo-dot-2" cx="68" cy="40" r="7" fill="#DE7539"/>
          <circle className="logo-dot-3" cx="86" cy="40" r="7" fill="#2CB392"/>
        </svg>
      </div>
    </div>
  );
};

export default LoadingAnimation; 