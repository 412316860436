import React from 'react';
import GlobalSidebar from './GlobalSidebar';
import TabMenuComponent from './TabMenu';
import Header from './Header';
import LoadingAnimation from './LoadingAnimation';
import './PageLayout.css';

const PageLayout = ({ 
  headerProps, 
  loading, 
  children,
  className = ''
}) => {
  return (
    <div>
      <GlobalSidebar />
      <Header {...headerProps} />
      <TabMenuComponent />
      <div className={`page-layout ${className}`}>
        {loading && <LoadingAnimation />}
        <div className="page-content">
          {children}
        </div>
      </div>
    </div>
  );
};

export default PageLayout; 