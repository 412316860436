import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import { Tooltip } from 'primereact/tooltip';
import './HotspotsBubbleChart.css';

const HotspotsBubbleChart = ({ hotspots, onBubbleSelect }) => {
    const svgRef = useRef(null);
    const containerRef = useRef(null);
    const simulationRef = useRef(null);
    const chartRef = useRef(null);
    const initialAnimationDone = useRef(false);
    const selectedBubbleRef = useRef(null);
    const [dimensions, setDimensions] = useState({ width: 0, height: 400 });
    const [tooltipContent, setTooltipContent] = useState('');
    const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
    const [showTooltip, setShowTooltip] = useState(false);
    const [isVisible, setIsVisible] = useState(false);

    // Domain color mapping with darker variants
    const domainColors = {
        'Backend': { main: '#2CB392', dark: '#229478' },
        'Frontend': { main: '#32AFC3', dark: '#2890A3' },
        'Infrastructure': { main: '#6366F1', dark: '#4F52C2' },
        'Database': { main: '#8B5CF6', dark: '#6F4AC4' },
        'Testing': { main: '#EC4899', dark: '#BE3A7A' },
        'Documentation': { main: '#F59E0B', dark: '#C47E09' },
        'Other': { main: '#9CA3AF', dark: '#6B7280' },
        'Unknown': { main: '#E5E7EB', dark: '#9CA3AF' }
    };

    // Constants for bubble sizes
    const MIN_BUBBLE_SIZE = 20;
    const MAX_BUBBLE_SIZE = 80;

    // Update dimensions when container size changes
    useEffect(() => {
        if (!containerRef.current) return;

        const updateDimensions = () => {
            const container = containerRef.current;
            if (container) {
                const { width } = container.getBoundingClientRect();
                const isMobile = width <= 640;
                const isTablet = width <= 1024 && width > 640;
                
                let height = 400; // default height
                if (isMobile) {
                    height = 350;
                } else if (isTablet) {
                    height = 400;
                }
                
                setDimensions({ 
                    width: width || 500,
                    height: height
                });
            }
        };

        updateDimensions();
        window.addEventListener('resize', updateDimensions);

        const resizeObserver = new ResizeObserver(() => {
            if (containerRef.current) {
                updateDimensions();
            }
        });

        if (containerRef.current) {
            resizeObserver.observe(containerRef.current);
        }

        return () => {
            window.removeEventListener('resize', updateDimensions);
            if (containerRef.current) {
                resizeObserver.disconnect();
            }
        };
    }, []);

    // Intersection Observer for visibility
    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                    observer.disconnect();
                }
            },
            { threshold: 0.2 }
        );

        if (chartRef.current) {
            observer.observe(chartRef.current);
        }

        return () => observer.disconnect();
    }, []);

    // Main chart rendering effect
    useEffect(() => {
        if (!dimensions.width || !svgRef.current || !hotspots?.length || !isVisible) return;

        const svg = d3.select(svgRef.current);
        svg.selectAll("*").remove();

        const margin = { top: 40, right: 40, bottom: 60, left: 40 };
        const width = dimensions.width - margin.left - margin.right;
        const height = dimensions.height - margin.top - margin.bottom;

        const g = svg
            .attr("width", width + margin.left + margin.right)
            .attr("height", height + margin.top + margin.bottom)
            .append("g")
            .attr("transform", `translate(${margin.left},${margin.top})`);

        // Create scale for bubble sizes
        const scoreExtent = d3.extent(hotspots, d => d.hotspot_score);
        const sizeScale = d3.scaleLinear()
            .domain(scoreExtent)
            .range([MIN_BUBBLE_SIZE, MAX_BUBBLE_SIZE]);

        // Create hierarchy data
        const hierarchyData = {
            name: "root",
            children: hotspots.map(hotspot => ({
                name: hotspot.filename,
                value: sizeScale(hotspot.hotspot_score),
                domain: hotspot.domain || 'Unknown',
                stats: hotspot.stats || {},
                hotspot_score: hotspot.hotspot_score
            }))
        };

        const root = d3.hierarchy(hierarchyData)
            .sum(d => d.value)
            .sort((a, b) => b.value - a.value);

        const nodes = d3.pack()
            .size([width, height])
            .padding(5)(root)
            .leaves();

        // Initialize force simulation
        simulationRef.current = d3.forceSimulation(nodes)
            .force("center", d3.forceCenter(width / 2, height / 2).strength(0.8))
            .force("charge", d3.forceManyBody().strength(d => -Math.pow(d.r, 2) * 0.5))
            .force("collide", d3.forceCollide().radius(d => d.r + 2).strength(0.8))
            .force("x", d3.forceX(width / 2).strength(0.2))
            .force("y", d3.forceY(height / 2).strength(0.2))
            .velocityDecay(0.3)
            .on("tick", () => {
                // Constrain the nodes within the bounds
                bubbles.attr("transform", d => {
                    d.x = Math.max(d.r, Math.min(width - d.r, d.x));
                    d.y = Math.max(d.r, Math.min(height - d.r, d.y));
                    return `translate(${d.x},${d.y})`;
                });
            });

        // Create bubble groups
        const bubbles = g.selectAll(".bubble")
            .data(nodes)
            .enter()
            .append("g")
            .attr("class", "bubble")
            .attr("transform", d => {
                d.x = width/2 + (Math.random() - 0.5) * 50;
                d.y = height/2 + (Math.random() - 0.5) * 50;
                return `translate(${d.x},${d.y})`;
            })
            .call(d3.drag()
                .on("start", (event, d) => {
                    if (!event.active) simulationRef.current.alphaTarget(0.3).restart();
                    d.fx = d.x;
                    d.fy = d.y;
                })
                .on("drag", (event, d) => {
                    d.fx = event.x;
                    d.fy = event.y;
                })
                .on("end", (event, d) => {
                    if (!event.active) simulationRef.current.alphaTarget(0);
                    d.fx = null;
                    d.fy = null;
                }));

        // Add circles with enter animation
        bubbles.append("circle")
            .attr("r", 0)
            .attr("fill", d => domainColors[d.data.domain]?.main || domainColors['Unknown'].main)
            .attr("opacity", 0.7)
            .attr("stroke", d => domainColors[d.data.domain]?.dark || domainColors['Unknown'].dark)
            .attr("stroke-width", 1)
            .transition()
            .duration(1000)
            .ease(d3.easeBounceOut)
            .attr("r", d => d.r)
            .on("end", () => {
                initialAnimationDone.current = true;
            });

        // Add click handler
        bubbles.on("click", (event, d) => {
            const isSelected = selectedBubbleRef.current?.name === d.data.name;
            
            g.selectAll("circle")
                .transition()
                .duration(200)
                .attr("opacity", node => isSelected ? 0.7 : (node.data.name === d.data.name ? 1 : 0.4))
                .attr("stroke-width", node => isSelected ? 1 : (node.data.name === d.data.name ? 2 : 1));

            selectedBubbleRef.current = isSelected ? null : d.data;
            onBubbleSelect?.(isSelected ? null : d.data);
        });

        // Add hover effects
        bubbles.on("mouseover", function(event, d) {
            if (d.data.name !== selectedBubbleRef.current?.name) {
                d3.select(this).select("circle")
                    .transition()
                    .duration(200)
                    .attr("opacity", 1)
                    .attr("stroke-width", 2);

                const stats = d.data.stats;
                const content = `
                    File: ${d.data.name}
                    Domain: ${d.data.domain}
                    Changes: ${stats.total_changes || 0}
                    Modified: ${stats.times_modified || 0} times
                    Additions: ${stats.total_additions || 0}
                    Deletions: ${stats.total_deletions || 0}
                    Hotspot Score: ${d.data.hotspot_score?.toFixed(2) || 0}
                `;
                
                const bubbleRect = this.getBoundingClientRect();
                const containerRect = containerRef.current.getBoundingClientRect();
                
                setTooltipPosition({
                    x: bubbleRect.left - containerRect.left + bubbleRect.width/2,
                    y: bubbleRect.top - containerRect.top
                });
                setTooltipContent(content);
                setShowTooltip(true);
            }
        })
        .on("mouseout", function(event, d) {
            if (d.data.name !== selectedBubbleRef.current?.name) {
                d3.select(this).select("circle")
                    .transition()
                    .duration(200)
                    .attr("opacity", selectedBubbleRef.current ? 0.4 : 0.7)
                    .attr("stroke-width", 1);
                setShowTooltip(false);
            }
        });

        // Add labels
        bubbles.append("text")
            .attr("dy", ".3em")
            .style("text-anchor", "middle")
            .style("font-size", d => Math.min(d.r / 3, 12) + "px")
            .style("fill", "#4B5563")
            .style("pointer-events", "none")
            .style("opacity", 0)
            .text(d => d.r > 20 ? d.data.name.split('/').pop() : '')
            .transition()
            .delay(1000)
            .duration(500)
            .style("opacity", 1);

        // Add legend at the bottom
        const domains = Object.keys(domainColors).filter(d => d !== 'Unknown');
        const legendItemWidth = width <= 640 ? 100 : 120;
        const legendHeight = width <= 640 ? 25 : 30;
        const legendPadding = width <= 640 ? 10 : 20;
        const maxLegendWidth = width - 2 * legendPadding;
        const itemsPerRow = Math.floor(maxLegendWidth / legendItemWidth);
        const rows = Math.ceil(domains.length / itemsPerRow);
        const totalHeight = rows * legendHeight;
        
        const legendGroup = svg.append("g")
            .attr("class", "legend")
            .attr("transform", `translate(${margin.left + legendPadding}, ${height + margin.top + legendPadding})`);

        domains.forEach((domain, i) => {
            const row = Math.floor(i / itemsPerRow);
            const col = i % itemsPerRow;
            const legendItem = legendGroup.append("g")
                .attr("transform", `translate(${col * legendItemWidth}, ${row * legendHeight})`);

            legendItem.append("circle")
                .attr("r", 6)
                .attr("cx", 10)
                .attr("cy", 10)
                .attr("fill", domainColors[domain].main)
                .attr("opacity", 0.7);

            legendItem.append("text")
                .attr("x", 25)
                .attr("y", 14)
                .text(domain)
                .style("font-size", "12px")
                .style("fill", "#4B5563");
        });

        // Update SVG height to accommodate wrapped legend
        svg.attr("height", height + margin.top + margin.bottom + totalHeight + 2 * legendPadding);

        return () => {
            if (simulationRef.current) simulationRef.current.stop();
        };
    }, [dimensions, hotspots, isVisible, onBubbleSelect]);

    return (
        <div className="hotspots-bubble-chart" ref={chartRef}>
            <div ref={containerRef} style={{ width: '100%', height: '100%' }}>
                {isVisible && (
                    <>
                        <svg ref={svgRef}></svg>
                        {showTooltip && (
                            <div 
                                className="bubble-tooltip"
                                style={{
                                    position: 'absolute',
                                    left: `${tooltipPosition.x}px`,
                                    top: `${tooltipPosition.y}px`,
                                    transform: 'translate(-50%, -100%)',
                                    opacity: 1
                                }}
                                data-pr-tooltip={tooltipContent}
                            />
                        )}
                        <Tooltip 
                            target=".bubble-tooltip"
                            position="top"
                            className="hotspots-tooltip"
                        />
                    </>
                )}
            </div>
        </div>
    );
};

export default HotspotsBubbleChart;