import React from 'react';
import './FormattedText.css';

const FormattedText = ({ text, className = '' }) => {
  if (!text) return null;

  const lines = text.split('\n');
  
  return (
    <div className={`formatted-text ${className}`}>
      {lines.map((line, index) => {
        // Determine line type
        const isSection = line.includes(':') && 
                         !line.startsWith('-') && 
                         !line.startsWith('*') && 
                         line.split(' ').length <= 10; // Only treat as section if it's short
        const isBullet = line.trim().startsWith('-');
        const isSubBullet = line.trim().startsWith('*');
        
        // Remove bullet point characters
        const cleanLine = line
          .replace(/^-\s*/, '')
          .replace(/^\*\s*/, '')
          .trim();

        // Transform **text** into <strong>text</strong>
        const formattedLine = cleanLine.split(/(\*\*.*?\*\*)/).map((part, i) => {
          if (part.startsWith('**') && part.endsWith('**')) {
            return <strong key={i}>{part.slice(2, -2)}</strong>;
          }
          return part;
        });
        
        return (
          <p 
            key={index}
            className={`
              formatted-text-line
              ${isSection ? 'section-line' : ''}
              ${isBullet ? 'bullet-line' : ''}
              ${isSubBullet ? 'sub-bullet-line' : ''}
            `}
          >
            {formattedLine}
          </p>
        );
      })}
    </div>
  );
};

export default FormattedText; 