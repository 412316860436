import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../axiosConfig';

// Create async thunk for fetching domains
export const fetchDomains = createAsyncThunk(
    'domains/fetchDomains',
    async (auditUuid) => {
        const response = await axiosInstance.get(`get_available_domains/${auditUuid}/`);
        return { auditUuid, domains: response.data };
    }
);

const initialState = {
    // Store domains by audit UUID
    domainsByAudit: {},
    loading: false,
    error: null
};

export const domainsSlice = createSlice({
    name: 'domains',
    initialState,
    reducers: {
        clearDomains: (state, action) => {
            const auditUuid = action.payload;
            delete state.domainsByAudit[auditUuid];
        },
        clearAllDomains: (state) => {
            state.domainsByAudit = {};
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchDomains.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchDomains.fulfilled, (state, action) => {
                const { auditUuid, domains } = action.payload;
                state.domainsByAudit[auditUuid] = domains;
                state.loading = false;
                state.error = null;
            })
            .addCase(fetchDomains.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    }
});

export const { clearDomains, clearAllDomains } = domainsSlice.actions;

// Selectors
export const selectDomainsByAuditId = (state, auditUuid) => state.domains.domainsByAudit[auditUuid];
export const selectDomainsLoading = (state) => state.domains.loading;
export const selectDomainsError = (state) => state.domains.error;

export default domainsSlice.reducer; 