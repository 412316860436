import React, { useState, useEffect, useRef } from 'react';
import { useParams, Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { checkAuditStatus } from '../utils/Actions';
import axiosInstance from '../axiosConfig';
import useAuditDetails from '../utils/useAuditDetails';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Message } from 'primereact/message';
import { Paginator } from 'primereact/paginator';
import { Panel } from 'primereact/panel';
import { Badge } from 'primereact/badge';

import GlobalSidebar from '../components/GlobalSidebar';
import DependencyGraph from './DependenciesGraph'; // This would be your D3.js component
import PageLayout from '../components/PageLayout';

import './Dependencies.css';

function DependenciesView() {
  const [dependenciesData, setDependenciesData] = useState({ sorted_packages: [] });

  const { auditUuid } = useParams();
  const dispatch = useDispatch();
  const auditAccessDetails = useSelector(state => state.audits[auditUuid]);
  const isMounted = useRef(true);
  const [shouldRedirect, setShouldRedirect] = useState(false);

  const [isGraphVisible, setIsGraphVisible] = useState(true);
  const [totalDependencies, setTotalDependencies] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isGraphLoading, setIsGraphLoading] = useState(true);
  const [unusedCount, setUnusedCount] = useState(0);

  // Use the new hook to get audit details
  const { 
    auditDetails, 
    headerProps, 
    loading: auditLoading, 
    isDataLoaded 
  } = useAuditDetails(auditUuid);

  useEffect(() => {
    if (!auditAccessDetails) {
    } else if (auditAccessDetails.error) {
      console.error('Audit access error:', auditAccessDetails.error);
      setShouldRedirect(true);
    } else if (!auditAccessDetails.isPublic && !auditAccessDetails.hasAccess) {
      setShouldRedirect(true);
    }
  }, [auditAccessDetails]);

  useEffect(() => {
    dispatch(checkAuditStatus(auditUuid));
  }, [dispatch, auditUuid]);
  
  useEffect(() => {
    // Skip data fetch if no access
    if (!auditAccessDetails || (!auditAccessDetails.isPublic && !auditAccessDetails.hasAccess)) {
      return;
    }

    const fetchDependencies = async () => {
      // Fetch dependencies
      const offset = (currentPage - 1) * rowsPerPage;
      setIsGraphLoading(true);

      axiosInstance.get(`dependencies/${auditUuid}/`, {
        params: {
          offset: offset,
          limit: rowsPerPage
        }
      })
        .then(response => {
          if (isMounted.current) {
            const cleanedPackages = response.data.sorted_packages.map(pkg => {
              return {
                ...pkg,
                package_name: removePackagePrefix(pkg.package_name)
              };
            });
            setTotalDependencies(response.data.total_count);
            const mergedData = mergePackageDetails(
              response.data.dependency_graph,
              cleanedPackages
            );
            setDependenciesData({
              dependency_graph: mergedData,
              sorted_packages: cleanedPackages
            });
            setUnusedCount(response.data.unused_count);
            setIsGraphLoading(false);
          }
        })
        .catch(error => {
          if (isMounted.current) {
            console.error('Error fetching dependencies:', error);
          }
          setIsGraphLoading(false);
        });
    };

    fetchDependencies();
  }, [auditUuid, currentPage, rowsPerPage, auditAccessDetails, isDataLoaded]);

  if (shouldRedirect) {
    return <Navigate to="/" />;
  }

// Helper function to merge package details into dependency graph nodes
const mergePackageDetails = (dependencyGraph, sortedPackages) => {
  if (!dependencyGraph || !dependencyGraph.nodes) {
    return { nodes: [], edges: [] };
  }

  const nodesWithDetails = dependencyGraph.nodes.map(node => {
    if (node.type === 'package') {
      // Find matching package in sortedPackages
      const packageDetails = sortedPackages.find(pkg => 
        removePackagePrefix(pkg.package_name) === removePackagePrefix(node.name)
      );
      return { ...node, ...packageDetails };
    }
    return node;
  });

  return {
    ...dependencyGraph,
    nodes: nodesWithDetails,
  };
};

  if (!dependenciesData) {
    return (
      <div>
        <GlobalSidebar />
      </div>
    );
  }

  const isGraphDataLoaded = dependenciesData && dependenciesData.dependency_graph &&
                            Array.isArray(dependenciesData.dependency_graph.nodes) &&
                            Array.isArray(dependenciesData.dependency_graph.edges) &&
                            dependenciesData.dependency_graph.nodes.length > 0 &&
                            dependenciesData.dependency_graph.edges.length > 0;


  const toggleGraphPanel = (event) => {
    setIsGraphVisible(prevState => !prevState);
  };

  // Helper function for text truncation and styling
  const truncateText = (text) => {
    if (text && text.length > 120) {  // Check if text is not undefined and has length greater than 120
      return text.substring(0, text.lastIndexOf(' ', 120)) + '...';
    }
    return text || '';  // Return an empty string if text is undefined or null
  };

  // General column template for truncating text
  const textTemplate = (rowData, field) => {
    const text = rowData[field];
    return <span>{truncateText(text)}</span>;
  };

  // Column templates
  const packageTemplate = (rowData) => {
    if (rowData.project_url) {
      return <a href={rowData.project_url} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'underline' }}>
        {truncateText(rowData.package_name)}
      </a>;
    }
    return truncateText(rowData.package_name);
  };

  const repositoryTemplate = (rowData) => {
    if (rowData.repository_url) {
      return <a href={rowData.repository_url} target="_blank" rel="noopener noreferrer">Link</a>;
    }
    return null;
  };

  const licenseTemplate = (rowData) => {
    if (rowData.licence_url) {
      return <a href={rowData.licence_url} target="_blank" rel="noopener noreferrer">{rowData.licence}</a>;
    }
    return rowData.licence;
  };

  const authorLink = (rowData) => {
    // Priority to author URL if available
    if (rowData.author_url) {
      return <a href={rowData.author_url} target="_blank" rel="noopener noreferrer">{rowData.author}</a>;
    }
    // Fallback to email if URL is not present
    else if (rowData.email) {
      return <a href={`mailto:${rowData.email}`} target="_blank" rel="noopener noreferrer">{rowData.author}</a>;
    }
    // If neither URL nor email is present, just return the author's name
    return rowData.author;
  };

  const renameMapping = {
      'times_used': 'Times used',
      'latest_version': 'Latest version',
      'package_name': 'Package name',
      'current_version': 'Declared version',
      'version': 'Declared version'
    };

  const desiredOrder = [
      'Package name',
      'Times used',
      'Status',
      'Declared version',
      'Latest version',
      'Author',
      'Licence',
      'Summary',  
      'Description'
    ];

  const maintenanceStatusTemplate = (rowData) => {
    const badges = [];
    
    // First add the maintenance status badge
    switch(rowData.status) {
      case 'active':
        badges.push(
          <div key="active" className="badge-tooltip-container">
            <Badge 
              value="Active" 
              severity="success" 
              className="mr-2" 
            />
            <div className="badge-tooltip">
              {rowData.last_publish_date ? 
                `Last update: ${new Date(rowData.last_publish_date).toLocaleDateString('en-GB')}` :
                'Package is actively maintained'}
            </div>
          </div>
        );
        break;

      case 'deprecated':
        badges.push(
          <div key="deprecated" className="badge-tooltip-container">
            <Badge 
              value="Deprecated" 
              severity="danger" 
              style={{ backgroundColor: '#E83636' }}
              className="mr-2" 
            />
            <div className="badge-tooltip">
              This package has been marked as deprecated by its maintainers
            </div>
          </div>
        );
        break;

      case 'built-in':
        badges.push(
          <div key="built-in" className="badge-tooltip-container">
            <Badge 
              value="Built-in" 
              severity="info" 
              className="mr-2" 
            />
            <div className="badge-tooltip">
              Standard library package
            </div>
          </div>
        );
        break;

      case 'unmaintained':
        badges.push(
          <div key="unmaintained" className="badge-tooltip-container">
            <Badge 
              value="Unmaintained" 
              severity="danger"
              style={{ backgroundColor: '#E83636' }}
              className="mr-2" 
            />
            <div className="badge-tooltip">
              {`No updates for over 12 months`}
            </div>
          </div>
        );
        break;

      case 'inactive':
        badges.push(
          <div key="inactive" className="badge-tooltip-container">
            <Badge 
              value="Inactive" 
              severity="warning" 
              className="mr-2" 
            />
            <div className="badge-tooltip">
              Package has not been updated recently
            </div>
          </div>
        );
        break;
    }

    // Then add version status badge if applicable
    if (rowData.latest_version && rowData.version && rowData.status !== 'built-in') {
      try {
        const current = rowData.version.split('.');
        const latest = rowData.latest_version.split('.');
        
        if (current.length >= 2 && latest.length >= 2) {
          const versionDiff = {
            major: Math.max(0, parseInt(latest[0]) - parseInt(current[0])),
            minor: Math.max(0, parseInt(latest[1]) - parseInt(current[1]))
          };
          
          if (versionDiff.major > 0 || versionDiff.minor > 0) {
            const diffText = [];
            if (versionDiff.major > 0) diffText.push(`${versionDiff.major} major`);
            if (versionDiff.minor > 0) diffText.push(`${versionDiff.minor} minor`);
            
            badges.push(
              <div key="outdated" className="badge-tooltip-container">
                <Badge 
                  value="Outdated" 
                  severity="warning" 
                  className="mr-2" 
                />
                <div className="badge-tooltip">
                  {`${diffText.join(', ')} versions behind latest (${rowData.latest_version})`}
                </div>
              </div>
            );
          }
        }
      } catch (e) {
        console.warn('Version comparison failed:', e);
      }
    }

    // Add error badge if there's a registry error
    if (rowData.registry_error) {
      badges.push(
        <div key="error" className="badge-tooltip-container">
          <Badge 
            value="Error" 
            severity="danger" 
            className="mr-2" 
          />
          <div className="badge-tooltip">
            {rowData.registry_error}
          </div>
        </div>
      );
    }
    
    return <div className="badge-container">{badges}</div>;
  };

  // Template for Times used column
  const timesUsedTemplate = (rowData) => {
    const timesUsed = rowData.times_used || 0;
    return timesUsed === 0 ? '---' : timesUsed.toString();
  };

  const getDynamicColumns = () => {
    const allKeys = new Set();
    dependenciesData.sorted_packages.forEach(pkg => {
      Object.keys(pkg).forEach(key => allKeys.add(key));
    });
  
    const excludedKeys = new Set([
      'is_deprecated', 
      'is_outdated', 
      'months_since_update', 
      'dependency_type', 
      'project_url', 
      'licence_url', 
      'repository_url', 
      'name', 
      'email', 
      'upload_time', 
      'author_url',
      'versions_behind',
      'maintenance_status', 
      'registry_error',
      'last_publish_date',
      'status'  // Exclude the plain status field as we'll handle it in maintenanceStatusTemplate
    ]);
  
    const dynamicColumns = Array.from(allKeys)
      .filter(key => !excludedKeys.has(key))
      .map(key => ({
        field: key,
        header: renameMapping[key] || key.charAt(0).toUpperCase() + key.slice(1),
        body: (rowData) => {
          if (key === 'package_name') {
            return packageTemplate(rowData);
          } else if (key === 'licence') {
            return licenseTemplate(rowData);
          } else if (key === 'repository_url') {
            return repositoryTemplate(rowData);
          } else if (key === 'author') {
            return authorLink(rowData);
          } else if (key === 'times_used') {
            return timesUsedTemplate(rowData);
          } else {
            return textTemplate(rowData, key);
          }
        }
      }));
  
    // Sort columns according to desired order
    dynamicColumns.sort((a, b) => {
      const indexA = desiredOrder.indexOf(a.header);
      const indexB = desiredOrder.indexOf(b.header);
      if (indexA === -1) return 1;  // Put unknown columns at the end
      if (indexB === -1) return -1;
      return indexA - indexB;
    });

    // Add the Status column after Latest Version
    const latestVersionIndex = dynamicColumns.findIndex(col => col.header === 'Latest Version');
    const statusColumn = {
      field: 'status',
      header: 'Status',
      body: maintenanceStatusTemplate
    };

    if (latestVersionIndex !== -1) {
      dynamicColumns.splice(latestVersionIndex + 1, 0, statusColumn);
    } else {
      dynamicColumns.push(statusColumn);
    }
  
    return dynamicColumns;
  };

  const removePackagePrefix = (packageName) => {
    if (!packageName) return '';
    const prefixPattern = /\$\![a-z]+\$!\_/i; // Regex to match the prefix pattern
    return packageName.replace(prefixPattern, ''); // Remove the prefix and return the name
  };

  const onPaginatorChange = (e) => {
    setCurrentPage(e.page + 1);  // Paginator component uses zero-based index for pages
    setRowsPerPage(e.rows);

  };


  const renderDependenciesTable = () => {
    if (!isDataLoaded) {
      return (
        <div className="dependency-loader-container">
          <div className="dependencygraph-loader"></div>
        </div>
      );
    }

    if (!dependenciesData || !dependenciesData.sorted_packages || dependenciesData.sorted_packages.length === 0) {
      return <Message severity="info" text="No dependencies found." />;
    }

    const dynamicColumns = getDynamicColumns();

    return (
      <>
        <DataTable value={dependenciesData.sorted_packages} stripedRows>
          {dynamicColumns.map((col, i) => (
            <Column key={i} field={col.field} header={col.header} body={col.body} />
          ))}
        </DataTable>
        <Paginator 
          first={(currentPage - 1) * rowsPerPage} 
          rows={rowsPerPage}
          totalRecords={totalDependencies}
          onPageChange={onPaginatorChange}
          rowsPerPageOptions={[10, 20, 50]} />
      </>
    );
  };

  return (
    <PageLayout 
      headerProps={headerProps}
      loading={auditLoading || isGraphLoading}
      className="dependencies-layout"
    >
      <div className="dependencies-container">
        {!(auditLoading || isGraphLoading) && (
          <>
            <div className="dependency-header">
              <p>
                In total <strong>{totalDependencies || 0}</strong> packages were found within {auditDetails?.file_count || 0} files screened.
              </p>
              {unusedCount > 0 && (
                <p className="unused-packages-warning">
                  <i className="pi pi-exclamation-triangle" style={{ marginRight: '8px', color: '#f59e0b' }}></i>
                  <strong>{unusedCount}</strong> packages are declared but were not found in the code. These could potentially be removed.
                </p>
              )}
            </div>
            <div className="dependencies-content">
              {renderDependenciesTable()}
            </div>
            <Panel className="dependency-graph" header="Dependency Graph" toggleable collapsed={!isGraphVisible} onToggle={toggleGraphPanel}>
              {isGraphVisible && isGraphLoading && (
                <div className="dependency-loader-container">
                  <div className="dependencygraph-loader"></div>
                </div>
              )}
              {isGraphVisible && !isGraphLoading && isGraphDataLoaded && <DependencyGraph dependencies={dependenciesData.dependency_graph} />}
              {isGraphVisible && !isGraphLoading && !isGraphDataLoaded && <Message severity="info" text="No dependency graph data available." />}
            </Panel>
          </>
        )}
      </div>
    </PageLayout>
  );
}

export default DependenciesView;
