import React, { useState, useEffect } from 'react';
import { Sidebar } from 'primereact/sidebar';
import { Button } from 'primereact/button';
import { ProgressBar } from 'primereact/progressbar';
import { ProgressSpinner } from 'primereact/progressspinner';
import { useNavigate, useLocation } from 'react-router-dom';
import './AuditScopeSummaryTabMenu.css';
import { useCsrfToken } from '../utils/CsrfTokenContext';
import axiosInstance from '../axiosConfig';
import { Message } from 'primereact/message';

const COMPLETED_STATUSES = [
    'Audit started',
    'File auditing completed',
    'Scores calculated',
    'Contextualization completed',
    'Contextualization failed',
    'Consolidation and analysis completed',
    'Audit failed',
    'Audit completed',
    'Process failed'
];

const AuditScopeSummaryTabMenu = ({ 
    visible, 
    onHide, 
    selectedNodes, 
    groupAuditUuid, 
    auditStatus,
    hasUnsavedChanges,
    onSelectionSaved
}) => {
    const { csrfToken } = useCsrfToken();
    const [scopeSummary, setScopeSummary] = useState({
        numberOfFiles: 0,
        linesOfCode: 0
    });
    const [locBudget, setLocBudget] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [auditFinished, setAuditFinished] = useState(false);
    const [isAuditInitiating, setIsAuditInitiating] = useState(false);
    const [auditReady, setAuditReady] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    const auditUuid = location.pathname.split('/')[1];
    const [accountUuid, setAccountUuid] = useState(""); 
    
    const handleContactSales = () => {
        window.location.href = 'mailto:sales@company.com';
    };

    // Add helper function to check audit status
    const isAuditCompleted = () => COMPLETED_STATUSES.includes(auditStatus);
    const isAuditDeleted = () => auditStatus === 'deleted';

    useEffect(() => {
        const persistRoot = JSON.parse(localStorage.getItem('persist:root'));
        const user = JSON.parse(persistRoot?.user || '{}');
        const fetchedAccountUuid = user.accountUuid || "";
    
        // Define fetchLocBudget inside useEffect to ensure it has access to the updated accountUuid
        const fetchLocBudget = async (accountUuid) => {
            if (!accountUuid) {
                console.error('Account UUID not found in local storage');
                return;
            }
        
            try {
                const response = await axiosInstance.get(`api/get_loc_buget/?account_uuid=${accountUuid}`);
                setLocBudget(response.data.loc_budget);
            } catch (error) {
                console.error('Error fetching LOC budget:', error);
            }
        };
    
        if (fetchedAccountUuid) {
            setAccountUuid(fetchedAccountUuid); // Update state
            fetchLocBudget(fetchedAccountUuid); // Fetch LOC budget with the newly fetched accountUuid
        }

        fetchScopeSummary(); // Fetch the scope summary on initial render
    
    }, []);

    // Add effect to update summary when selectedNodes changes
    useEffect(() => {
        const calculateSummary = () => {
            const numberOfFiles = selectedNodes.filter(node => node.data.type !== 'Folder').length;
            const linesOfCode = selectedNodes.reduce((total, node) => {
                if (node.data.type !== 'Folder') {
                    return total + (node.data.lines_of_code || 0);
                }
                return total;
            }, 0);

            setScopeSummary({
                numberOfFiles,
                linesOfCode
            });
        };

        calculateSummary();
    }, [selectedNodes]);

    // Refactored fetchScopeSummary as a separate function
    const fetchScopeSummary = async () => {
        try {
            const response = await axiosInstance.get(`api/audit_scope_summary/${auditUuid}/`);
            setScopeSummary({
                numberOfFiles: response.data.number_files,
                linesOfCode: response.data.lines_of_code
            });
        } catch (error) {
            console.error('Error fetching scope summary:', error);
        }
    };

    const onConfirmClick = async () => {
        if (isAuditCompleted() || isAuditDeleted()) return;

        try {
            setIsLoading(true);
            // Filter out folders and only include files
            const filePaths = selectedNodes
                .filter(node => node.data.type !== 'Folder')
                .map(node => node.data.fullPath);
            
            const fileSelectionPayload = {
                files: filePaths,
                auditUuid: auditUuid,
            };
            await axiosInstance.post(`api/store_file_selection/`, fileSelectionPayload );

            await fetchScopeSummary(); // Refetch the scope summary after updating file selection
           
            setAuditReady(true); // Enable the audit start section
            onSelectionSaved(); // Reset the unsaved changes flag
        } catch (error) {
            console.error('Error in process:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const navigateToAuditReport = () => {
        navigate(`/${auditUuid}/audit_summary`);
    };

    const onStartAuditClick = async () => {

        setIsAuditInitiating(true); // Set audit initiation state

        if (!accountUuid) {
            console.error('Account UUID not found in local storage');
            setIsLoading(false);
            return;
        }

        setIsLoading(true); // Set loading state
        console.log('Starting audit with auditUuid:', auditUuid);
        axiosInstance.post(`send_audit_started_email/`, {
            auditUuid: auditUuid
        })
        .then(response => {
            console.log('Email sent successfully');
        })
        .catch(error => {
            console.error('Error sending email:', error);
        });
    
        // Trigger the main process but don't wait for it to complete
        await axiosInstance.post(`trigger_main_process/`, 
            { auditUuid: auditUuid, accountUuid: accountUuid }, 
            )
            .then(response => {
                setAuditFinished(true);
            })
            .catch(error => {
                console.error('Error in triggering main process:', error);
            })
            .finally(() => {
                setIsLoading(false); // Reset loading state
                onHide(); // Hide the sidebar
            });

        axiosInstance.post(`api/update_loc_budget/`, {
            accountUuid: accountUuid, // Ensure this is the actual accountUuid from your storage or state
            linesOfCodeUsed: scopeSummary.linesOfCode
        }, {
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': csrfToken 
            },
            withCredentials: true
        });
    
        // Immediately navigate to the audit summary view
        navigate(`/${auditUuid}/audit_summary`);
    };  

    const isConfirmDisabled = !selectedNodes || 
                            selectedNodes.length === 0 || 
                            auditFinished || 
                            isAuditInitiating || 
                            isLoading || 
                            isAuditCompleted() ||
                            isAuditDeleted() ||
                            (auditReady && !hasUnsavedChanges); // Add condition for unsaved changes

    const noFilesSelected = !selectedNodes || selectedNodes.length === 0;

    const handleBackToOverview = () => {
        if (groupAuditUuid) {
            navigate(`/${groupAuditUuid}/audit-invitation`, {
                state: { groupAuditUuid }
            });
        }
    };

    return (
        <Sidebar visible={true} modal={false} position="right" style={{ width: '300px' }} onHide={onHide}>
             <div className={`audit-summary ${isAuditDeleted() ? 'disabled' : ''}`}>
                <h2>Audit scope</h2>
                <h4>The following content will be audited:</h4>
                <div className="files-to-audit">
                    <span><strong>{(scopeSummary.numberOfFiles || 0).toLocaleString('de-DE')}</strong></span>
                    <label> files</label>
                </div>
                <div className="lines-of-code">
                    <span><strong>{(scopeSummary.linesOfCode || 0).toLocaleString('de-DE')}</strong></span>
                    <label> total lines of code</label>
                </div>
            </div>
            <Button 
                label="Save File Selection" 
                className="confirm-button" 
                onClick={onConfirmClick} 
                disabled={isConfirmDisabled} 
                style={{marginTop:'1rem', minWidth:'95%' }}
            />

            {noFilesSelected && <div>No files selected.</div>}
            {isLoading && <ProgressBar mode="indeterminate" style={{ height: '6px', marginTop: '10px' }} />} 
    
            {/* Conditional rendering based on userRole and groupAuditUuid */}
            {!(groupAuditUuid) && (
                <>
                    {/* New Section for starting the audit */}
                    {(auditReady || isAuditCompleted()) && (
                    <div className={`audit-order ${isAuditDeleted() ? 'disabled' : ''}`}>
                        {/* Add warning message when there are unsaved changes */}
                        {hasUnsavedChanges && !isAuditCompleted() && (
                            <Message 
                                severity="warn" 
                                text='Selection was changed. If desired, confirm with "Save file selection".'
                                style={{ margin: '1rem 0' }}
                            />
                        )}
                        
                        <h2>Checkout</h2>

                        <div className="audit-info-row">
                            <span>Free LoC:</span>
                            <span className="audit-value"><b>{locBudget.toLocaleString('de-DE', {maximumFractionDigits: 0})}</b></span>
                        </div>

                        <div className="audit-info-row-small">
                            <span style={{fontStyle: "italic", fontSize: "14px" }}>After this audit:</span>
                            <span className="audit-value" style={{fontStyle: "italic", fontSize: "12px" }}>
                                <i style={{fontStyle: "italic", fontSize: "14px" }}>{Math.max(0, locBudget - scopeSummary.linesOfCode).toLocaleString('de-DE', {maximumFractionDigits: 0})}</i>
                            </span>
                        </div>

                        <div className="audit-info-row">
                            <span>From selection:</span>
                            <span className="audit-value"><b>{scopeSummary.linesOfCode.toLocaleString('de-DE', {maximumFractionDigits: 0})}</b></span>
                        </div>

                        <hr className="audit-divider" />

                        <div className="audit-info-row">
                            <span>Payable LoC:</span>
                            <span className="audit-value"><b>{Math.max(0, scopeSummary.linesOfCode - locBudget).toLocaleString('de-DE', {maximumFractionDigits: 0})}</b></span>
                        </div>

                        {/* Start Audit Button */}
                        {isAuditCompleted() ? (
                                <Button 
                                    label="To Audit Report"
                                    icon="pi pi-file"
                                    className="to-audit-report-button"
                                    onClick={navigateToAuditReport}
                                    disabled={isAuditDeleted()}
                                    style={{backgroundColor: '#32AFC3', color: 'white', minWidth: '95%', marginTop: '1rem'}}
                                />
                            ) : (
                                <Button 
                                    label="Start Audit"
                                    className={`start-audit-button ${isLoading ? 'p-button-text p-button-plain' : ''}`}
                                    onClick={onStartAuditClick}
                                    icon={isLoading ? 'pi pi-spin pi-spinner' : 'pi pi-bolt'}
                                    disabled={isLoading || 
                                            locBudget < scopeSummary.linesOfCode || 
                                            auditFinished || 
                                            isAuditInitiating || 
                                            isAuditDeleted()}
                                />
                            )}
                        
                        {isLoading && <ProgressSpinner style={{ width: '20px', height: '20px' }} strokeWidth="4" />}
                        {locBudget < scopeSummary.linesOfCode && !isAuditCompleted() && (
                            <>
                                <div className="audit-warning">Insufficient LOC budget to start the audit.</div>
                                <Button 
                                    label="Talk to sales"
                                    icon="pi pi-arrow-right"
                                    className="talk-to-sales-button"
                                    onClick={handleContactSales}
                                    style={{
                                        backgroundColor: '#323232',
                                        color: 'white',
                                        border: '2px solid #323232',
                                        minWidth: '95%',
                                        marginTop: '1rem',
                                        fontWeight: '600'
                                    }}
                                />
                            </>
                        )}
                        </div>
                )}
                </>
            )}

                {groupAuditUuid && (
                <Button 
                    label="Back to Overview"
                    icon="pi pi-angle-left"
                    className="back-button"
                    style={{marginTop: '25px', minWidth: '95%', border: '1px solid #8D8D8D'}}
                    onClick={handleBackToOverview}
                    disabled={isAuditDeleted()}
                />
            )}
        </Sidebar>
    );
    
};

export default AuditScopeSummaryTabMenu;
