import React, { useState, useEffect, useRef } from 'react';
import { TabMenu } from 'primereact/tabmenu';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Menu } from 'primereact/menu';
import { Button } from 'primereact/button';

import './TabMenu.css';

const TabMenuComponent = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const auditUuid = location.pathname.split('/')[1];
  const [activeIndex, setActiveIndex] = useState(0);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const menu = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const items = [
    { label: 'Summary', icon: 'pi pi-fw pi-home', url: `/${auditUuid}/audit_summary` },
    { label: 'Flags', icon: 'pi pi-fw pi-flag', url: `/${auditUuid}/flags` },
    { label: 'Development', icon: 'pi pi-fw pi-code', url: `/${auditUuid}/development` },
    { label: 'Dependencies', icon: 'pi pi-fw pi-pencil', url: `/${auditUuid}/dependencies` },
    { label: 'Files', icon: 'pi pi-fw pi-file', url: `/${auditUuid}/files` },
  ];

  useEffect(() => {
    const index = items.findIndex(item => location.pathname === item.url);
    setActiveIndex(index !== -1 ? index : 0);
  }, [location.pathname, items]);

  const handleItemClick = (index) => {
    setActiveIndex(index);
    navigate(items[index].url);
    if (isMobile) {
      setIsMenuVisible(false);
    }
  };

  const toggleMenu = (event) => {
    if (menu.current) {
      menu.current.toggle(event);
    }
    setIsMenuVisible(prev => !prev);
  };

  const menuModel = items.map((item, index) => ({
    label: item.label,
    icon: item.icon,
    command: () => handleItemClick(index),
  }));

  return (
    <div className="tab-menu-container">
      {isMobile ? (
        <>
          <div className="mobile-tab-header">
            <span className="current-tab">
              <i className={items[activeIndex].icon}></i>
              {items[activeIndex].label}
            </span>
            <Menu model={menuModel} popup ref={menu} id="popup_menu" onHide={() => setIsMenuVisible(false)} />
            <Button icon="pi pi-bars" onClick={toggleMenu} aria-controls="popup_menu" aria-haspopup />
          </div>
        </>
      ) : (
        <TabMenu 
          model={items.map((item, index) => ({
            ...item,
            command: () => handleItemClick(index),
            template: (item, options) => (
              <a 
                className={`p-menuitem-link ${location.pathname === item.url ? 'active-tab' : ''}`}
                onClick={(e) => {
                  e.preventDefault();
                  handleItemClick(index);
                }}
                href={item.url}
              >
                <span className="p-menuitem-icon">
                  <i className={item.icon}></i>
                </span>
                <span className="p-menuitem-text">{item.label}</span>
              </a>
            )
          }))} 
          activeIndex={activeIndex} 
          className="custom-tabmenu" 
        />
      )}
    </div>
  );
};

export default TabMenuComponent;