import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { useRef } from 'react';
import './AuditBadgeHelper.css';
import axiosInstance from '../axiosConfig';

const AuditBadgeHelper = ({ auditUuid }) => {
    const [loading, setLoading] = useState(true);
    const toast = useRef(null);
    
    // Construct URLs using environment variables
    const baseUrl = process.env.FRONTEND_URL || window.location.origin;
    const backendUrl = process.env.REACT_APP_API_URL || baseUrl.replace(':3000', ':8000');
    
    // Construct badge and audit URLs
    const badgeUrl = `${backendUrl}audit_badge/${auditUuid}`;
    const auditUrl = `${baseUrl}/${auditUuid}/audit_summary`;
    
    useEffect(() => {
        const validateBadge = async () => {
            try {
                // Just verify the badge endpoint is accessible
                await axiosInstance.get(`audit_badge/${auditUuid}/`);
                setLoading(false);
            } catch (error) {
                console.error('Error validating badge:', error);
                toast.current.show({
                    severity: 'error',
                    summary: 'Error',
                    detail: 'Failed to validate badge. The badge may still work when added to your README.',
                    life: 3000
                });
                setLoading(false);
            }
        };

        validateBadge();
    }, [auditUuid]);
    
    const markdownCode = `[![CodeDD Audit](${badgeUrl})](${auditUrl})`;
    
    const copyToClipboard = () => {
        navigator.clipboard.writeText(markdownCode).then(() => {
            toast.current.show({
                severity: 'success',
                summary: 'Copied!',
                detail: 'Badge markdown copied to clipboard',
                life: 3000
            });
        }).catch(() => {
            toast.current.show({
                severity: 'error',
                summary: 'Error',
                detail: 'Failed to copy to clipboard',
                life: 3000
            });
        });
    };
    
    return (
        <div className="badge-helper-content">
            <Toast ref={toast} />
            
            <h3>Preview</h3>
            <div className="badge-preview">
                {loading ? (
                    <div>Loading badge preview...</div>
                ) : (
                    <img 
                        src={badgeUrl} 
                        alt="CodeDD Audit Badge"
                        onError={(e) => {
                            e.target.onerror = null;
                            toast.current.show({
                                severity: 'warn',
                                summary: 'Preview Failed',
                                detail: 'Could not load badge preview, but the badge may still work in your README.',
                                life: 5000
                            });
                        }}
                    />
                )}
            </div>
            
            <h3>Markdown Code</h3>
            <div className="badge-code">
                <InputText value={markdownCode} readOnly className="w-full" />
                <Button 
                    icon="pi pi-copy"
                    onClick={copyToClipboard}
                    tooltip="Copy to clipboard"
                    className="p-button-rounded p-button-text"
                />
            </div>
            
            <div className="badge-instructions">
                <h3>Instructions</h3>
                <ol>
                    <li>Copy the markdown code above</li>
                    <li>Paste it in your repository's README.md file</li>
                    <li>Commit and push the changes</li>
                </ol>
                <p>The badge will automatically update when your audit scores change.</p>
            </div>
        </div>
    );
};

export default AuditBadgeHelper; 