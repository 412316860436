import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Pricing.css';

const PricingCard = ({ title, description, isAvailable, buttonText, gradient }) => {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate('/contact-sales');
  };

  return (
    <div className={`pricing-card ${gradient}`}>
      <div className="pricing-card-content">
        <h2>{title}</h2>
        <p>{description}</p>
        {isAvailable ? (
          <button className="pricing-card-button" onClick={handleButtonClick}>
            {buttonText}
          </button>
        ) : (
          <div className="pricing-card-unavailable">
            Coming soon
          </div>
        )}
      </div>
    </div>
  );
};

const Pricing = () => {
  const pricingOptions = [
    {
      title: "Per Audit",
      description: "Perfect for starting your technical due diligence journey. Pay per audit based on lines of code analyzed, giving you complete cost control.",
      isAvailable: false,
      buttonText: "Get Started",
      gradient: "gradient-1"
    },
    {
      title: "Premium",
      description: "Ideal for scaling organizations requiring continuous code audits. Monthly lines of code budget with flexible allocation across projects.",
      isAvailable: false,
      buttonText: "Choose Premium",
      gradient: "gradient-2"
    },
    {
      title: "Enterprise",
      description: "For enterprises looking to scale technical due diligence. Custom pricing models, dedicated support, and organization-wide security compliance.",
      isAvailable: true,
      buttonText: "Contact Sales",
      gradient: "gradient-3"
    }
  ];

  return (
    <div className="pricing-page">
      <div className="pricing-header">
        <div className="pricing-header-content">
          <h1>Learn about our pricing</h1>
          <p>CodeDD is still in early access. We currently limit access to our platform in order to maintain high availability and best customer support.</p>
        </div>
      </div>

      <div className="pricing-content">
        <div className="pricing-cards">
          {pricingOptions.map((option, index) => (
            <PricingCard
              key={index}
              title={option.title}
              description={option.description}
              isAvailable={option.isAvailable}
              buttonText={option.buttonText}
              gradient={option.gradient}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Pricing;
