import React from 'react';
import { Tooltip } from 'primereact/tooltip';
import './AuditScoreBar.css';

const AuditScoreBar = ({ title, score, auditId }) => {

    // Determine color based on score
    const getColor = (score) => {
        if (score === 0) return '#E83636';
        if (score < 33) return '#E83636';
        if (score < 66) return '#DE7539';
        return '#2CB392';
    };

    // Format the title for display
    const formatTitle = (title) => {
        return title
            .replace('overall_', '')
            .replace('_score', '')
            .split('_')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    };

    const tooltipId = `score-tooltip-${auditId}-${title}`;
    const roundedScore = Math.round(score || 0); // Handle undefined scores
    const color = getColor(roundedScore);

    return (
        <div className="audit-score-bar-container">
            <div 
                id={tooltipId}
                className="audit-score-bar"
            >
                {roundedScore === 0 ? (
                    <div 
                        className="zero-score-indicator"
                        style={{ backgroundColor: color }}
                    />
                ) : (
                    <div 
                        className="audit-score-fill"
                        style={{ 
                            width: `${roundedScore}%`,
                            backgroundColor: color
                        }}
                    />
                )}
            </div>
            <Tooltip
                target={`#${tooltipId}`}
                position="top"
                mouseTrack
                mouseTrackTop={10}
                style={{
                    color: 'white',
                    fontWeight: 'bold',
                    fontSize: '12px',
                    padding: '4px 8px',
                    borderRadius: '4px'
                }}
            >
                {`${formatTitle(title)}: ${roundedScore}%`}
            </Tooltip>
        </div>
    );
};

export default AuditScoreBar; 