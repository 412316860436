import React, { useState, useEffect, useCallback } from 'react';
import { InputText } from 'primereact/inputtext';
import { Helmet } from 'react-helmet-async';
import axiosInstance from '../../axiosConfig';
import './Discover.css';
import { Message } from 'primereact/message';
import { useNavigate } from 'react-router-dom';
import { Paginator } from 'primereact/paginator';
import { ProgressSpinner } from 'primereact/progressspinner';
import AuditScoreBar from '../../user_dashboard/components/AuditScoreBar';

const DiscoverHero = () => {
  return (
    <div className="discover-hero">
      <div className="discover-hero-content">
        <div className="discover-hero-text">
          <h3>Explore and Learn</h3>
          <div className="hero-title">
            <div className="gradient-wrapper">
              <span className="hero-gradient-text">Public</span>
            </div>
            <h1>Repository Audits</h1>
          </div>
          <p className="hero-description-discover">
            Discover public source code audits from various repositories. Learn from real-world examples 
            and see how different codebases are structured and evaluated.
          </p>
        </div>
      </div>
    </div>
  );
};

const PublicRepoCard = ({ audit, userTimeZone }) => {
  const navigate = useNavigate();
  const [truncatedUrl, setTruncatedUrl] = useState('');
  const urlContainerRef = React.useRef(null);

  const getDomainLogo = (repo_url) => {
    try {
      const domain = new URL(repo_url).hostname;
      const domainLogos = {
        "github.com": '/images/GitHub.png',
        "gitlab.com": '/images/GitLab.png',
        "bitbucket.org": "/images/Bitbucket.png",
        "dev.azure.com": "/images/Azure.png",
        "azure.com": "/images/Azure.png",
        "SourceForge.net": "/images/SourceForge.png",
        "code.google.com": "/images/GoogleCode.png",
        "codeplex.com": "/images/CodePlex.png",
        "launchpad.net": "/images/Launchpad.png",
        "savannah.gnu.org": "/images/Savannah.png",
        "freecode.com": "/images/Freecode.png",
        "gitkraken.com": "/images/GitKraken.png",
        "beanstalkapp.com": "/images/Beanstalk.png",
        "assembla.com": "/images/Assembla.png",
        "phabricator.com": "/images/Phabricator.png",
        "gogs.io": "/images/Gogs.png",
        "gitea.com": "/images/Gitea.png",
        "gitbucket.com": "/images/GitBucket.png",
        "codeberg.org": "/images/Codeberg.png",
      };
      return domainLogos[domain] || '/images/default-logo.png';
    } catch (e) {
      return '/images/default-logo.png';
    }
  };

  const getElapsedTime = (utcTimeString, userTimeZone) => {
    const utcDate = new Date(utcTimeString);
    const currentTime = new Date();
    const diffInSeconds = Math.floor((currentTime - utcDate) / 1000);

    if (diffInSeconds < 60) return 'Just now';
    if (diffInSeconds < 3600) return `${Math.floor(diffInSeconds / 60)} minutes ago`;
    if (diffInSeconds < 86400) return `${Math.floor(diffInSeconds / 3600)} hours ago`;
    if (diffInSeconds < 604800) return `${Math.floor(diffInSeconds / 86400)} days ago`;
    if (diffInSeconds < 2592000) return `${Math.floor(diffInSeconds / 604800)} weeks ago`;
    if (diffInSeconds < 31536000) return `${Math.floor(diffInSeconds / 2592000)} months ago`;
    return `${Math.floor(diffInSeconds / 31536000)} years ago`;
  };

  const truncateUrl = (url, containerWidth) => {
    try {
      const urlObj = new URL(url);
      const domain = urlObj.hostname;
      const path = urlObj.pathname;
      
      const avgCharWidth = 8;
      const maxChars = Math.floor(containerWidth / avgCharWidth);
      const availableChars = maxChars - 3;
      
      if (domain.length + path.length <= availableChars) {
        return url;
      }
      
      if (domain.length > availableChars - 3) {
        return domain.substring(0, availableChars) + '...';
      }
      
      const remainingChars = availableChars - domain.length;
      const truncatedPath = path.length > remainingChars 
        ? path.substring(0, remainingChars - 3) + '...'
        : path;
      
      return `${domain}${truncatedPath}`;
    } catch (e) {
      return url.substring(0, Math.max(containerWidth / 8 - 3, 10)) + '...';
    }
  };

  const handleClick = () => {
    navigate(`/${audit.audit_uuid}/audit_summary`);
    console.log('navigating to:', audit.audit_uuid);
  };

  useEffect(() => {
    if (audit.repo_url && urlContainerRef.current) {
      const containerWidth = urlContainerRef.current.offsetWidth;
      const newTruncatedUrl = truncateUrl(audit.repo_url, containerWidth);
      setTruncatedUrl(newTruncatedUrl);
    }
  }, [audit.repo_url]);

  return (
    <div className="audit-item" onClick={handleClick}>
      <img src={getDomainLogo(audit.repo_url)} className="repo-logo" alt="Repository Logo" />
      <div className="audit-details">
        <div className="repo-name-container">
            <i className="pi pi-globe public-icon" />
            <span className="repo-name">{audit.audit_name}</span>
        </div>
        <div className="repo-url-container" ref={urlContainerRef}>
          <span className="repo-url">{truncatedUrl}</span>
        </div>
      </div>
      <div className="audit-info">
        <span className="ai-synthesis">
          {getElapsedTime(audit.ai_synthesis, userTimeZone)}
        </span>
        <span className="file-count">
          {`${audit.number_files} files audited`}
        </span>
        <span className="audit-status">
          {audit.audit_status}
        </span>
      </div>
      {audit.scores && (
        <div className="audit-scores-grid">
          {Object.entries(audit.scores).map(([key, value]) => (
            <AuditScoreBar
              key={`${audit.audit_uuid}-${key}`}
              title={key}
              score={value}
              auditId={audit.audit_uuid}
            />
          ))}
        </div>
      )}
    </div>
  );
};

const AuditSearch = ({ onSearch }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [searchTimeout, setSearchTimeout] = useState(null);

  const handleSearch = (e) => {
    const query = e.target.value;
    setSearchQuery(query);

    // Clear existing timeout
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    // Set new timeout for search
    const timeoutId = setTimeout(() => {
      if (onSearch) {
        onSearch(query);
      }
    }, 300); // 300ms debounce

    setSearchTimeout(timeoutId);
  };

  return (
    <div className="audit-search">
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          value={searchQuery}
          onChange={handleSearch}
          placeholder="Search public audits..."
          className="search-input"
        />
      </span>
    </div>
  );
};

const Discover = () => {
  const [audits, setAudits] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const [offset, setOffset] = useState(0);
  const [hasLoadedMore, setHasLoadedMore] = useState(false);
  const [rateLimitError, setRateLimitError] = useState(null);
  const [currentSearchQuery, setCurrentSearchQuery] = useState('');
  const limit = 10;
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const navigate = useNavigate();

  const fetchAudits = useCallback(async (loadMore = false, searchQuery = '') => {
    if (isLoading) return;
    
    setIsLoading(true);
    setRateLimitError(null);
    
    try {
      const endpoint = searchQuery ? 'search_public_audits/' : 'public_audits/';
      const params = {
        offset: loadMore ? offset + limit : 0,
        limit: limit
      };

      if (searchQuery) {
        params.query = searchQuery;
      }

      const response = await axiosInstance.get(endpoint, { params });

      const fetchedAudits = response.data.audits || [];
      const totalCount = response.data.total_audits || fetchedAudits.length;

      if (loadMore) {
        setAudits(prev => [...prev, ...fetchedAudits]);
        setOffset(offset + limit);
        setHasLoadedMore(true);
      } else {
        setAudits(fetchedAudits);
        setOffset(0);
      }
      
      setTotalItems(totalCount);
    } catch (error) {
      console.error('Error fetching public audits:', error);
      if (error.response?.status === 429) {
        // Rate limit exceeded
        setRateLimitError({
          message: error.response.data.message,
          currentCount: error.response.data.current_count,
          limit: error.response.data.limit,
          window: error.response.data.window
        });
      }
    } finally {
      setIsLoading(false);
    }
  }, [offset, limit]);

  useEffect(() => {
    let mounted = true;

    const initialFetch = async () => {
      if (mounted) {
        await fetchAudits(false);
      }
    };

    initialFetch();

    return () => {
      mounted = false;
    };
  }, []);

  const handleSearch = useCallback((query) => {
    setCurrentSearchQuery(query);
    if (query.length >= 2) {
      fetchAudits(false, query);
    } else if (query.length === 0) {
      fetchAudits(false);
    }
  }, [fetchAudits]);

  const handlePageChange = useCallback((e) => {
    const newOffset = e.first;
    setOffset(newOffset);
    fetchAudits(true, currentSearchQuery);
  }, [fetchAudits, currentSearchQuery]);

  return (
    <div className="discover-page">
      <Helmet>
        <title>Discover Public Audits | CodeDD</title>
        <meta name="description" content="Explore public code audits from various repositories. Learn from real-world examples and see how different codebases are structured and maintained." />
      </Helmet>

      <DiscoverHero />

      <section className="audits-section">
        <div className="audits-content">
          <AuditSearch onSearch={handleSearch} />
          
          {rateLimitError && (
            <div className="rate-limit-error">
              <Message 
                severity="warn" 
                text={`${rateLimitError.message} You've made ${rateLimitError.currentCount} requests out of ${rateLimitError.limit} allowed within ${rateLimitError.window}.`}
              />
            </div>
          )}
          
          <div className={`audits-list ${rateLimitError ? 'hidden' : ''}`}>
            {isLoading ? (
              <div className="loading-spinner-container">
                <ProgressSpinner style={{width: '50px', height: '50px'}} />
              </div>
            ) : (
              <>
                {audits.map((audit) => (
                  <PublicRepoCard 
                    key={audit.audit_uuid} 
                    audit={audit} 
                    userTimeZone={userTimeZone} 
                  />
                ))}
                
                {audits.length === 0 && currentSearchQuery && (
                  <div className="no-results-message">
                    <i className="pi pi-info-circle" />
                    <p>No audits found matching your search criteria</p>
                    <p className="no-results-cta">
                      Why don't you create a new public audit of your desired repository for others to see?
                    </p>
                    <div className="no-results-buttons">
                      <button 
                        className="no-results-secondary-button"
                        onClick={() => navigate('/login')}
                      >
                        Login
                      </button>
                      <button 
                        className="no-results-primary-button"
                        onClick={() => navigate('/register')}
                      >
                        Start free trial
                      </button>
                    </div>
                  </div>
                )}

                {audits.length > 0 && (
                  <div className="paginator-container">
                    <Paginator
                      first={offset}
                      rows={limit}
                      totalRecords={totalItems}
                      onPageChange={handlePageChange}
                      template="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
                      className="public-audits-paginator"
                    />
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Discover;
