import React from 'react';
import { Button } from 'primereact/button';
import AccountCard from './AccountCard';
import ProfileCard from './ProfileCard';
import './Account.css';

const Account = () => {
    const [activeMenu, setActiveMenu] = React.useState('profile');

    const renderContent = () => {
        switch (activeMenu) {
            case 'profile':
                return <ProfileCard />;
            case 'account':
                return <AccountCard />;
            default:
                return <div>Select an option</div>;
        }
    };

    return (
        <div className="account-container">
            <div className="account-menu">
                <Button
                    label="Profile"
                    className={`p-button-text ${activeMenu === 'profile' ? 'p-button-primary' : ''}`}
                    onClick={() => setActiveMenu('profile')}
                    icon="pi pi-user"
                />
                <Button
                    label="Account"
                    className={`p-button-text ${activeMenu === 'account' ? 'p-button-primary' : ''}`}
                    onClick={() => setActiveMenu('account')}
                    icon="pi pi-cog"
                />
            </div>
            <div className="account-content">
                {renderContent()}
            </div>
        </div>
    );
};

export default Account;