import React from 'react';
import { TreeTable } from 'primereact/treetable';
import { Column } from 'primereact/column';
import { Sidebar } from 'primereact/sidebar';
import './AuditScopeSelectionSkeleton.css';

const AuditScopeSelectionSkeleton = () => {
    // Generate skeleton data with a structure matching the real data
    const generateSkeletonData = (depth = 0, count = 5) => {
        return Array(count).fill(null).map((_, index) => ({
            key: `skeleton-${depth}-${index}`,
            data: {
                name: '',
                type: depth === 0 ? 'Folder' : 'Source Code',
                size: '',
                is_selected: false,
                fullPath: '',
                displayPath: ''
            },
            children: depth < 2 ? generateSkeletonData(depth + 1, Math.max(2, count - 2)) : []
        }));
    };

    const skeletonNodes = generateSkeletonData();

    const skeletonTemplate = () => {
        return <div className="skeleton-cell-content"></div>;
    };

    return (
        <div className="audit-scope-selection skeleton">
            {/* Main Content */}
            <div className="main-content">
                {/* Header Section */}
                <div className="header-text">
                    <h2>Select files you want to include in your due diligence.</h2>
                    <p className="sub-text">
                        CodeDD automatically will only select source-code files to process.
                    </p>
                    <p className="sub-text">
                        If you want to include or exclude other files, please select them manually.
                    </p>
                </div>

                {/* TreeTable */}
                <TreeTable 
                    value={skeletonNodes} 
                    tableStyle={{ minWidth: '50rem' }}
                >
                    <Column 
                        headerStyle={{ width: '3rem' }} 
                        body={skeletonTemplate}
                    />
                    <Column 
                        header="Name" 
                        body={skeletonTemplate}
                        expander
                    />
                    <Column 
                        header="Lines of Code" 
                        body={skeletonTemplate}
                    />
                    <Column 
                        header="Type" 
                        body={skeletonTemplate}
                    />
                </TreeTable>
            </div>

            {/* Sidebar */}
            <Sidebar 
                visible={true} 
                modal={false} 
                position="right" 
                style={{ width: '300px' }}
                className="skeleton-sidebar"
            >
                <div className="audit-summary skeleton">
                    <h2>Audit scope</h2>
                    <h4>The following content will be audited:</h4>
                    
                    <div className="files-to-audit">
                        <div className="bouncing-lines">
                            <span>-</span>
                            <span>-</span>
                            <span>-</span>
                        </div>
                        <label>files</label>
                    </div>

                    <div className="lines-of-code">
                        <div className="bouncing-lines">
                            <span>-</span>
                            <span>-</span>
                            <span>-</span>
                        </div>
                        <label>total lines of code</label>
                    </div>

                    <p className="processing-message">
                        Files are being processed, please wait until complete to select the content to be audited.
                    </p>
                </div>
            </Sidebar>
        </div>
    );
};

export default AuditScopeSelectionSkeleton;
