import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { 
  fetchAuditDetails, 
  selectAuditDetails, 
  selectAuditHeaderProps, 
  selectAuditLoading, 
  selectAuditError 
} from '../features/audits/auditDetailsSlice';

export const useAuditDetails = (auditUuid) => {
  const dispatch = useDispatch();
  const auditDetails = useSelector(state => selectAuditDetails(state, auditUuid));
  const headerProps = useSelector(state => selectAuditHeaderProps(state, auditUuid));
  const loading = useSelector(selectAuditLoading);
  const error = useSelector(selectAuditError);
  const lastFetchTime = useRef(0);

  useEffect(() => {
    const now = Date.now();
    const timeSinceLastFetch = now - lastFetchTime.current;
    
    // Only fetch if:
    // 1. We don't have the details
    // 2. AND we're not currently loading
    // 3. AND it's been at least 2 seconds since our last fetch
    const shouldFetch = !auditDetails && 
      !loading &&
      timeSinceLastFetch >= 2000;

    if (shouldFetch) {
      console.log('Dispatching fetchAuditDetails from useAuditDetails hook');
      lastFetchTime.current = now;
      dispatch(fetchAuditDetails(auditUuid));
    }
  }, [auditUuid, auditDetails, loading, dispatch]);

  return {
    auditDetails,
    headerProps,
    loading,
    error,
    isDataLoaded: !!auditDetails,
  };
};

export default useAuditDetails; 