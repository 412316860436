import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import axiosInstance from '../axiosConfig';
import { Toast } from 'primereact/toast';
import InviterView from './audit_invitation_components/InviterView';
import InviteeView from './audit_invitation_components/InviteeView';
import RepositoryCard from '../components/RepositoryCard';
import AuditInvitationSummaryTab from './audit_invitation_components/AuditInvitationSummaryTab';
import EnterRepoCredentials from '../components/EnterRepoCredentials';
import useRepositories from './hooks/UseRepositories';

import './AuditInvitation.css';

const AuditInvitation = () => {
    const [gitUrl, setGitUrl] = useState('');
    const [isValidUrl, setIsValidUrl] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [isCheckingUrl, setIsCheckingUrl] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [errorType, setErrorType] = useState('');
    const [userRole, setUserRole] = useState(null);
    const [userName, setUserName] = useState('');
    const [auditName, setAuditName] = useState('');
    const [email, setInviteeEmail] = useState('');

    const persistRoot = JSON.parse(localStorage.getItem('persist:root'));
    const user = JSON.parse(persistRoot?.user || '{}');
    const accountUuid = user.accountUuid;

    const location = useLocation();
    const groupAuditUuid = location.pathname.split('/')[1];

    const { repositoriesData, isLoadingRepositories, error: repositoriesError, fetchRepositories } = useRepositories(groupAuditUuid);

    const [submittedUrls, setSubmittedUrls] = useState([]);
    const [showCredentialsModal, setShowCredentialsModal] = useState(false);

    const [auditStarted, setAuditStarted] = useState(false);
    const [auditFinished, setAuditFinished] = useState(false);

    const toast = useRef(null);

    const [isDuplicateRepo, setIsDuplicateRepo] = useState(false);
    const [warningMessage, setWarningMessage] = useState('');

    const [importStatus, setImportStatus] = useState({
        status: '',
        progress: 0,
        message: ''
    });
    const [currentImportUuid, setCurrentImportUuid] = useState(null);
    const pollingRef = useRef({
        timeoutId: null,
        pollCount: 0,
        isPolling: false
    });

    useEffect(() => {
        const identifyUserRole = async () => {
            try {
                const response = await axiosInstance.get('identify_audit_role/', {
                    params: {
                        audit_uuid: groupAuditUuid,
                        account_uuid: accountUuid
                    }
                });
                setUserRole(response.data.role);
                setUserName(response.data.inviterName);
                setAuditName(response.data.auditName);
                setInviteeEmail(response.data.email);
            } catch (error) {
                setErrorMessage("Error identifying user role.");
                setErrorType('roleIdentificationError');
                console.error(error);
            }
        };

        identifyUserRole();
    }, [groupAuditUuid, accountUuid]);

    const isValidGitUrl = (url) => {
        const pattern = /^(https?:\/\/)?(www\.)?(github\.com|gitlab\.com|bitbucket\.org|sourceforge\.net|code.google.com|codeplex\.com|launchpad\.net|savannah\.gnu\.org|freecode\.com|gitkraken\.com|beanstalkapp\.com|assembla\.com|phabricator\.com|gogs\.io|gitea\.com|gitbucket\.github\.io|codeberg\.org|azure\.com|dev\.azure\.com|bitbucket\.com|sr\.ht|dags\.hub\.com|chiselapp\.com|repo\.or\.cz|notabug\.org|kallithea-scm\.org|git\.savannah\.gnu\.org|repo\.openpandora\.org|git\.kernel\.org)\/[\w\-\.\/]+$/;
        return pattern.test(url);
    };

    const handleInputChange = (event) => {
        const newUrl = event.target.value;
        setGitUrl(newUrl);
        setIsValidUrl(true);
        setErrorMessage('');
        setErrorType('');
        setWarningMessage('');
        
        if (newUrl && repositoriesData?.repositories?.length > 0) {
            
            const normalizedNewUrl = normalizeGitUrl(newUrl);
            const isDuplicate = repositoriesData.repositories.some(repo => {
                const normalizedRepoUrl = normalizeGitUrl(repo.url);
                return normalizedRepoUrl === normalizedNewUrl;
            });
            
            if (isDuplicate) {
                setWarningMessage('This repository has already been added to the audit list.');
                setIsDuplicateRepo(true);
            } else {
                setIsDuplicateRepo(false);
            }
        } else {
            setIsDuplicateRepo(false);
        }
    };

    const normalizeGitUrl = (url) => {
        let normalized = url.replace(/\.git$/, '');
        normalized = normalized.replace(/\/$/, '');
        normalized = normalized.replace(/^https?:\/\//, '');
        normalized = normalized.replace(/^www\./, '');
        return normalized.toLowerCase();
    };

    const getProgressFromStatus = (status) => {
        const progressMap = {
            'Audit session created': 20,
            'Repository imported': 40,
            'Repository structure imported': 60,
            'File information imported': 100
        };
        return progressMap[status] || 0;
    };

    const getProgressMessage = (status) => {
        const messageMap = {
            'Audit session created': 'Creating audit session...',
            'Repository imported': 'Importing repository files...',
            'Repository structure imported': 'Analyzing repository structure...',
            'File information imported': 'Import completed!'
        };
        return messageMap[status] || 'Processing repository...';
    };

    const pollAuditStatus = useCallback(async (uuid) => {
        if (!uuid || pollingRef.current.isPolling) {
            return;
        }

        try {
            pollingRef.current.isPolling = true;
            const response = await axiosInstance.get('audit_status/', {
                params: { audit_uuid: uuid }
            });
            
            if (response.data.audit_status) {
                const status = response.data.audit_status;
                const progress = getProgressFromStatus(status);
                const message = getProgressMessage(status);
                
                setImportStatus({
                    status,
                    progress,
                    message
                });
                
                if (status === 'File information imported' || status === 'Import failed') {
                    setIsLoading(false);
                    setCurrentImportUuid(null);
                    await fetchRepositories();
                    return true;
                }
            }
            return false;
        } catch (error) {
            console.error('Error polling audit status:', error);
            setIsLoading(false);
            setCurrentImportUuid(null);
            return true;
        } finally {
            pollingRef.current.isPolling = false;
        }
    }, [fetchRepositories]);

    // Polling effect with useRef for stable state
    useEffect(() => {
        const startPolling = async () => {
            if (!currentImportUuid || pollingRef.current.pollCount >= 20) {
                return;
            }

            const poll = async () => {
                if (pollingRef.current.pollCount >= 20) {
                    return;
                }

                pollingRef.current.pollCount++;
                const shouldStop = await pollAuditStatus(currentImportUuid);

                if (!shouldStop) {
                    pollingRef.current.timeoutId = setTimeout(poll, 5000);
                }
            };

            await poll();
        };

        if (currentImportUuid) {
            pollingRef.current = {
                timeoutId: null,
                pollCount: 0,
                isPolling: false
            };
            startPolling();
        }

        return () => {
            if (pollingRef.current.timeoutId) {
                clearTimeout(pollingRef.current.timeoutId);
            }
        };
    }, [currentImportUuid, pollAuditStatus]);

    const handleSubmit = async () => {
        if (isLoading) return;

        setIsLoading(true);
        setErrorMessage('');
        setErrorType('');
        setIsCheckingUrl(true);
        setIsValidUrl(true);
    
        if (!isValidGitUrl(gitUrl)) {
            setErrorMessage("Invalid Git URL format.");
            setErrorType('invalidUrlFormat');
            setIsValidUrl(false);
            setIsCheckingUrl(false);
            setIsLoading(false);
            return;
        }
        
        try {
            const validationResponse = await axiosInstance.post(
                'validate_git_url', 
                { 
                    git_repo_url: gitUrl, 
                    account_uuid: accountUuid 
                }
            );
            
            setIsCheckingUrl(false);

            if (validationResponse.data.isValid) {
                setImportStatus({
                    status: 'Audit session created',
                    progress: 20,
                    message: 'Creating audit session...'
                });

                const auditScopeResponse = await axiosInstance.post(
                    'post_audit_scope_selector/',
                    { 
                        git_repo_url: gitUrl, 
                        account_uuid: accountUuid, 
                        g_audit_uuid: groupAuditUuid 
                    }
                );

                if (auditScopeResponse.data.success) {
                    setSubmittedUrls(prev => [...prev, gitUrl]);
                    setGitUrl('');
                    setIsDuplicateRepo(false);
                    setWarningMessage('');
                    
                    const newAuditUuid = auditScopeResponse.data.audit_uuid;
                    
                    await fetchRepositories();
                    
                    showSuccess(gitUrl);
                    
                    setTimeout(() => {
                        setCurrentImportUuid(newAuditUuid);
                    }, 1000);
                } else {
                    handleValidationError(validationResponse.data.errorType);
                    setIsLoading(false);
                }
            } else {
                handleValidationError(validationResponse.data.errorType);
                setIsLoading(false);
            }
        } catch (error) {
            console.error('Error in submission:', error);
            handleError(error);
        }
    };

    const handleValidationError = (errorType) => {
        switch (errorType) {
            case 'invalidUrl':
                setErrorMessage("This is not a valid URL.");
                setErrorType('invalidUrl');
                setIsValidUrl(false);
                break;
            case 'invalidGitUrl':
                setErrorMessage("This is not a valid git root URL to clone.");
                setErrorType('invalidGitUrl');
                setIsValidUrl(false);
                break;
            case 'accessError':
                setErrorMessage("The git URL is protected and could not be accessed.");
                setErrorType('accessError');
                setIsValidUrl(false);
                break;
            default:
                setErrorMessage("An unknown error occurred.");
                setErrorType('unknown');
                setIsValidUrl(false);
                break;
        }
    };

    const handleError = (error) => {
        setIsCheckingUrl(false);
        setIsLoading(false);
    
        if (error.response && error.response.data) {
            const { message, errorType } = error.response.data;
            
            if (message === "SSH access denied.") {
                setShowCredentialsModal(true);
            } else {
                setErrorMessage(message);
                setErrorType(errorType);
                setIsValidUrl(false);
            }
        } else {
            console.error('Error:', error);
            setErrorMessage('An error occurred while processing the URL.');
            setErrorType('unknown');
            setIsValidUrl(false);
        }
    };

    const showSuccess = (url) => {
        toast.current.show({severity: 'success', summary: 'URL Submitted', detail: url, life: 3000});
    };

    return (
        <div className="audit-invitation-container">
            <EnterRepoCredentials
                isVisible={showCredentialsModal}
                onHide={() => setShowCredentialsModal(false)}
                gitUrl={gitUrl}
                style={{ width: '900px' }}
                onAuthenticate={handleSubmit}
                isLoading={isLoading}
                accountUuid={accountUuid}
            />
            <Toast ref={toast} />
            <AuditInvitationSummaryTab
                key={JSON.stringify(repositoriesData)}
                visible={true} 
                onHide={() => {}}
                userRole={userRole}
                repositoriesData={repositoriesData}
                groupAuditUuid={groupAuditUuid}
                userName={userName}
                fetchRepositories={fetchRepositories}
                auditStarted={auditStarted}
                setAuditStarted={setAuditStarted}
                setAuditFinished={setAuditFinished}
                auditFinished={auditFinished}
            />
            <div className="audit-invitation-content">
                {userRole === 'inviter' && (
                    <InviterView 
                        auditName={auditName}
                        userRole={userRole}
                        userName={userName}
                        inviteeEmail={email}
                        auditFinished={auditFinished}
                    />
                )}
                {userRole === 'invitee' && (
                    <InviteeView
                        auditName={auditName}
                        gitUrl={gitUrl}
                        isCheckingUrl={isCheckingUrl}
                        isValidUrl={isValidUrl}
                        isLoading={isLoading}
                        errorMessage={errorMessage}
                        handleInputChange={handleInputChange}
                        handleSubmit={handleSubmit}
                        userName={userName}
                        showCredentialsModal={showCredentialsModal}
                        auditFinished={auditFinished}
                        auditStarted={auditStarted}
                        isDuplicateRepo={isDuplicateRepo}
                        warningMessage={warningMessage}
                        importStatus={importStatus}
                    />
                )}
            </div>
            <div className="repo-list-container">
                <RepositoryCard 
                    fetchRepositories={fetchRepositories} 
                    className="repository-card"
                    style={{alignItems: "self-start"}}
                    repositories={repositoriesData.repositories}
                    groupAuditUuid={groupAuditUuid}
                    userRole={userRole}
                    auditStarted={auditStarted}
                    auditFinished={auditFinished}
                    isLoading={isLoadingRepositories}
                    error={repositoriesError}
                />
            </div>
        </div>
    );
};

export default AuditInvitation;