import React from 'react';
import './Terms.css';  // We'll reuse the Terms styling

const Impressum = () => {
  return (
    <div className="terms-page">
      <header className="terms-header">
        <h1 className="terms-logo">CodeDD</h1>
        <h2 className="terms-title">Legal Notice (Impressum)</h2>
      </header>
      
      <div className="terms-container">
        <h3 className="terms-section-title">Information according to § 5 ECG</h3>
        
        <h4 className="terms-subsection-title">Company Details</h4>
        <p className="terms-paragraph">
          CodeDD e.U.<br />
          Wötzling 2<br />
          3233 Kilb<br />
          Austria
        </p>

        <h4 className="terms-subsection-title">Management</h4>
        <p className="terms-paragraph">
          Managing Director: Mag. Camillo Pachmann
        </p>

        <h4 className="terms-subsection-title">Contact Information</h4>
        <p className="terms-paragraph">
          Email: info@codedd.ai<br />
          Website: www.codedd.ai
        </p>

        <h4 className="terms-subsection-title">Commercial Register Information</h4>
        <p className="terms-paragraph">
          Commercial Register Court: Landesgericht St. Pölten<br />
          Company Registration Number: FN 644525g<br />
        </p>

        <h4 className="terms-subsection-title">Professional Law</h4>
        <p className="terms-paragraph">
          Trade Regulations: Gewerbeordnung<br />
          Trade Authority: Bezirkshauptmannschaft Melk
        </p>

        <h4 className="terms-subsection-title">Chamber Membership</h4>
        <p className="terms-paragraph">
          Chamber: Wirtschaftskammer Niederösterreich<br />
          Professional Group: Information & Consulting
        </p>

        <h4 className="terms-subsection-title">Object of Business</h4>
        <p className="terms-paragraph">
          Development and operation of software solutions for code analysis and auditing using artificial intelligence.
        </p>

        <h3 className="terms-section-title">Online Dispute Resolution</h3>
        <p className="terms-paragraph">
          The European Commission provides a platform for online dispute resolution (OS) which is accessible at{' '}
          <a href="https://ec.europa.eu/consumers/odr" className="terms-url" target="_blank" rel="noopener noreferrer">
            https://ec.europa.eu/consumers/odr
          </a>
        </p>

        <h3 className="terms-section-title">Basic Direction</h3>
        <p className="terms-paragraph">
          Information about our company's services in the field of software development and artificial intelligence, 
          specifically focusing on code analysis and security auditing using large language models.
        </p>

        <h3 className="terms-section-title">Disclaimer</h3>
        <p className="terms-paragraph">
          Despite careful content control, we assume no liability for the content of external links. 
          The operators of the linked pages are solely responsible for their content.
        </p>

        <h3 className="terms-section-title">Copyright</h3>
        <p className="terms-paragraph">
          The contents and works created by the site operators on these pages are subject to Austrian copyright law. 
          The reproduction, editing, distribution, and any kind of exploitation outside the limits of copyright law 
          require the written consent of CodeDD e.U.
        </p>
      </div>
    </div>
  );
};

export default Impressum;
