import React, { useState, useRef } from 'react';
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import { Password } from 'primereact/password';
import { Toast } from 'primereact/toast';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../features/user/userSlice';
import axiosInstance from '../../axiosConfig';
import './AccountCard.css';

const AccountCard = () => {
    const [isChangingPassword, setIsChangingPassword] = useState(false);
    const [loading, setLoading] = useState(false);
    const [passwords, setPasswords] = useState({
        currentPassword: '',
        newPassword: '',
        confirmPassword: ''
    });
    const [errors, setErrors] = useState({});
    const toast = useRef(null);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const accountUuid = useSelector(state => state.user.accountUuid);

    const validatePasswords = () => {
        const newErrors = {};
        
        if (!passwords.currentPassword) {
            newErrors.currentPassword = 'Current password is required';
        }
        
        if (!passwords.newPassword) {
            newErrors.newPassword = 'New password is required';
        } else if (passwords.newPassword.length < 8) {
            newErrors.newPassword = 'Password must be at least 8 characters long';
        } else if (!/[A-Z]/.test(passwords.newPassword)) {
            newErrors.newPassword = 'Password must contain at least one uppercase letter';
        } else if (!/[a-z]/.test(passwords.newPassword)) {
            newErrors.newPassword = 'Password must contain at least one lowercase letter';
        } else if (!/[0-9]/.test(passwords.newPassword)) {
            newErrors.newPassword = 'Password must contain at least one number';
        }
        
        if (!passwords.confirmPassword) {
            newErrors.confirmPassword = 'Please confirm your password';
        } else if (passwords.newPassword !== passwords.confirmPassword) {
            newErrors.confirmPassword = 'Passwords do not match';
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const isFormValid = () => {
        return passwords.currentPassword && 
               passwords.newPassword && 
               passwords.newPassword.length >= 8 &&
               passwords.confirmPassword &&
               passwords.newPassword === passwords.confirmPassword;
    };

    const handlePasswordChange = async (e) => {
        e.preventDefault();
        
        if (!validatePasswords()) {
            return;
        }

        setLoading(true);
        try {
            await axiosInstance.post('confirm-password-reset/', {
                current_password: passwords.currentPassword,
                new_password: passwords.newPassword,
                account_uuid: accountUuid
            });

            toast.current.show({
                severity: 'success',
                summary: 'Success',
                detail: 'Password changed successfully. A confirmation email has been sent.',
                life: 3000
            });

            setIsChangingPassword(false);
            setPasswords({
                currentPassword: '',
                newPassword: '',
                confirmPassword: ''
            });
            setErrors({});
        } catch (error) {
            const errorMessage = error.response?.data?.message || 'Failed to change password';
            toast.current.show({
                severity: 'error',
                summary: 'Error',
                detail: errorMessage,
                life: 3000
            });
        } finally {
            setLoading(false);
        }
    };

    const handleSignOut = async () => {
        try {
            await axiosInstance.post('logout/');
            dispatch(logout());
            navigate('/login');
        } catch (error) {
            console.error('Error during sign out:', error);
            dispatch(logout());
            navigate('/login');
        }
    };

    const renderPasswordChange = () => (
        <form onSubmit={handlePasswordChange} className="password-change-form">
            <div className="form-header">
                <h3>Change Password</h3>
                <p>Please enter your current password and choose a new one</p>
            </div>
            <div className="p-field">
                <label htmlFor="currentPassword">Current password</label>
                <Password
                    id="currentPassword"
                    value={passwords.currentPassword}
                    onChange={(e) => setPasswords({ ...passwords, currentPassword: e.target.value })}
                    className={`w-full ${errors.currentPassword ? 'p-invalid' : ''}`}
                    feedback={false}
                    toggleMask
                    required
                    inputRef={(el) => {
                        if (el && el.querySelector('input')) {
                            el.querySelector('input').tabIndex = 1;
                            const toggleButton = el.querySelector('button');
                            if (toggleButton) {
                                toggleButton.tabIndex = -1;
                            }
                        }
                    }}
                />
                {errors.currentPassword && (
                    <small className="p-error">{errors.currentPassword}</small>
                )}
            </div>
            <div className="p-field">
                <label htmlFor="newPassword">New password</label>
                <Password
                    id="newPassword"
                    value={passwords.newPassword}
                    onChange={(e) => setPasswords({ ...passwords, newPassword: e.target.value })}
                    className={`w-full ${errors.newPassword ? 'p-invalid' : ''}`}
                    toggleMask
                    required
                    promptLabel="Choose a password"
                    weakLabel="Too simple"
                    mediumLabel="Average complexity"
                    strongLabel="Complex password"
                    inputRef={(el) => {
                        if (el && el.querySelector('input')) {
                            el.querySelector('input').tabIndex = 2;
                            const toggleButton = el.querySelector('button');
                            if (toggleButton) {
                                toggleButton.tabIndex = -1;
                            }
                        }
                    }}
                />
                {errors.newPassword && (
                    <small className="p-error">{errors.newPassword}</small>
                )}
                <small className="p-help">
                    Password must be at least 8 characters long and contain uppercase, lowercase, and numbers
                </small>
            </div>
            <div className="p-field">
                <label htmlFor="confirmPassword">Confirm new password</label>
                <Password
                    id="confirmPassword"
                    value={passwords.confirmPassword}
                    onChange={(e) => setPasswords({ ...passwords, confirmPassword: e.target.value })}
                    className={`w-full ${errors.confirmPassword ? 'p-invalid' : ''}`}
                    feedback={false}
                    toggleMask
                    required
                    inputRef={(el) => {
                        if (el && el.querySelector('input')) {
                            el.querySelector('input').tabIndex = 3;
                            const toggleButton = el.querySelector('button');
                            if (toggleButton) {
                                toggleButton.tabIndex = -1;
                            }
                        }
                    }}
                />
                {errors.confirmPassword && (
                    <small className="p-error">{errors.confirmPassword}</small>
                )}
            </div>
            <div className="password-actions">
                <Button
                    type="button"
                    label="Cancel"
                    className="p-button-text"
                    onClick={() => {
                        setIsChangingPassword(false);
                        setPasswords({
                            currentPassword: '',
                            newPassword: '',
                            confirmPassword: ''
                        });
                        setErrors({});
                    }}
                    disabled={loading}
                    tabIndex={5}
                />
                <Button
                    type="submit"
                    label="Change password"
                    className="confirm-password-change-button"
                    loading={loading}
                    disabled={!isFormValid() || loading}
                    tabIndex={4}
                />
            </div>
        </form>
    );

    const renderAccountOptions = () => (
        <div className="account-options">
            <div className="account-option">
                <div className="option-content">
                    <h3>Change password</h3>
                    <p>Update your password to keep your account secure</p>
                </div>
                <Button
                    label="Change password"
                    className="change-password-button"
                    onClick={() => setIsChangingPassword(true)}
                />
            </div>
            <div className="account-option">
                <div className="option-content">
                    <h3>Sign out</h3>
                    <p>Sign out from your account</p>
                </div>
                <Button
                    label="Sign out"
                    className="sign-out-button"
                    onClick={handleSignOut}
                />
            </div>
        </div>
    );

    return (
        <Card className="account-card">
            <Toast ref={toast} />
            <div className="account-header">
                <h2>Account</h2>
                <p className="account-subtitle">Manage your account settings</p>
            </div>
            {isChangingPassword ? renderPasswordChange() : renderAccountOptions()}
        </Card>
    );
};

export default AccountCard; 