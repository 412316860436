import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../axiosConfig';

// Create async thunk for fetching file types
export const fetchFileTypes = createAsyncThunk(
    'fileTypes/fetchFileTypes',
    async (auditUuid) => {
        const response = await axiosInstance.get(`get_available_file_types/${auditUuid}/`);
        return { auditUuid, fileTypes: response.data };
    }
);

const initialState = {
    // Store file types by audit UUID
    fileTypesByAudit: {},
    loading: false,
    error: null
};

export const fileTypesSlice = createSlice({
    name: 'fileTypes',
    initialState,
    reducers: {
        clearFileTypes: (state, action) => {
            const auditUuid = action.payload;
            delete state.fileTypesByAudit[auditUuid];
        },
        clearAllFileTypes: (state) => {
            state.fileTypesByAudit = {};
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchFileTypes.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchFileTypes.fulfilled, (state, action) => {
                const { auditUuid, fileTypes } = action.payload;
                state.fileTypesByAudit[auditUuid] = fileTypes;
                state.loading = false;
                state.error = null;
            })
            .addCase(fetchFileTypes.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    }
});

export const { clearFileTypes, clearAllFileTypes } = fileTypesSlice.actions;

// Selectors
export const selectFileTypesByAuditId = (state, auditUuid) => state.fileTypes.fileTypesByAudit[auditUuid];
export const selectFileTypesLoading = (state) => state.fileTypes.loading;
export const selectFileTypesError = (state) => state.fileTypes.error;

export default fileTypesSlice.reducer; 